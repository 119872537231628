import React, { useState, useRef, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HelpModal from './HelpModal';
import InfoMessage from './InfoMessage';
import YesNoQuestion from './YesNoQuestion';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        // width: '85%',
        // height: '85%'
    },
    stepper: {
        height: '75%',
        justifyContent: 'space-evenly'
    },
    label: {
        fontSize: '1rem',
    },
    spinner: {
        position: 'absolute',
        margin: '1rem'
    },
    error: {
        minHeight: 'calc(3rem + 6px)'
    }
})



const ZoomChecker = React.memo(({ onComplete, onError, noRetry }) => {
    const styles = useStyles();
    const [status, setStatus] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const {t} = useTranslation('self_tech_check_step_6');

    const helpComponents = {
        "Missing Zoom": {
            message: () => <div>{t('latest_zoom_needed')}<br/>{t('download_zoom_here')}<a href="https://zoom.us/download" taret="_blank">https://zoom.us/download</a>.<br /><br />{t('return_after_setting_zoom')}</div>
        }
    }

    const handleQuestionAnswer = useCallback((yes) => {
        if (yes) {
            // const callback = status.questionIndex < checkQuestions.length - 1 ? () => {
            //     setStatus(prev => ({
            //         videoComplete: true,
            //         questionIndex: prev.questionIndex + 1
            //     }))
            // } : undefined
            onComplete()
        } else {
            const answerError = new Error('Missing Zoom')
            onComplete(() => {
                setStatus({
                    failed: true,
                    error: answerError
                })
                onError(answerError)
            })
        }
    }, [onComplete, onError])

    return (
        <div className={styles.root}>
            <YesNoQuestion
                onAnswer={handleQuestionAnswer}
                question={t('ask_zoom_installed')}
                submitted={submitted}
                allowChange={true}
            />
            <InfoMessage
                {...status}
                messages={helpComponents}
                // defaultMessage="There was an error. Please click below to retry."
                // noRetry={noRetry}
            />
        </div>
    )
})

export default ZoomChecker;