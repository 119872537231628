import React, { useState, useRef, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useKinesisWebRTC } from './useKinesisWebRTC';
import Dial from './dial/Dial'
import clsx from "clsx";
import config from './config.json'

const useStyles = makeStyles({
    root: {
        position: 'absolute',
        top: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1001,
        overflow: 'hidden'
    },
    container: {
        width: '100%',
        height: ({ ratio }) => ratio ? `${ratio * 100}vw` : '100%',
        maxHeight: '100%',
        maxWidth: ({ ratio }) => ratio ? `${1/ratio * 100}vh` : '100%',
        margin: 'auto',
        display: 'flex',
        // flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        opacity: 0.45,
        filter: 'brightness(1.75) drop-shadow(0px 0px 1px white)',
        boxSizing: 'border-box'
    },
    dialBox: {
        width: '100%',
        transform: 'scale(0.15)',
        transformOrigin: 'bottom right',
        marginRight: '1.5%',
        opacity: ({ dataChannelConnected }) => dataChannelConnected ? 1 : 0.5,
        filter: ({ dataChannelConnected }) => dataChannelConnected ? 'none' : 'sepia(100%) hue-rotate(315deg) saturate(350%)'
    },
    disconnected: {
        animation: `$blinker 1s linear infinite`,
    },
    tuneIn: {
        animation: `$blinker 1s linear infinite`,
    },
    tuneOut: {
        animation: `$blinker 1s linear infinite`,
    },
    "@keyframes blinker": {
        "50%": {
            opacity: 0
        },
    }
})

// const API_ROOT = 'https://qd737x8npf.execute-api.us-west-1.amazonaws.com'
const region = 'us-west-2';

const DialViewer = React.memo(({ sendData, sendMessageRef, sessionId, userId, show, viewRatio, onScoreChange, onConnectionChange, token, ...dialProps }) => {
    const [currentAngle, setCurrentAngle] = useState(135)
    const STATIC_OPTIONS = useMemo(() => ({
        httpOpts: {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        },
        // onOpen: () => {

        // },
        shouldReconnect: (closeEvent) => {
            // connected.current = false;
            return true
        },
    }), [token]);
    const { sendMessage, lastMessage, dataChannelConnected } = useKinesisWebRTC(`https://${config.rest.sessionGuestAPI}/rtc?sessionId=${sessionId}&userId=${userId}&role=MASTER`, 'MASTER', region, STATIC_OPTIONS)

    const styles = useStyles({ ratio: viewRatio, dataChannelConnected });

    const onChange = useRef()
    onChange.current = onScoreChange
    const sendRef = useRef()
    sendRef.current = sendMessage
    const sendDataRef = useRef()
    sendDataRef.current = sendData

    if (sendMessageRef) sendMessageRef.current = sendMessage

    useEffect(() => {
        sendRef.current = sendMessage
        if (sendMessageRef) sendMessageRef.current = sendMessage
    }, [sendMessage, sendMessageRef])

    useEffect(() => {
        onChange.current = onScoreChange
    }, [onScoreChange])

    useEffect(() => {
        if (dataChannelConnected && sendDataRef.current) sendRef.current(JSON.stringify({
            action: 'startDial'
        }))
        if (onConnectionChange) onConnectionChange(dataChannelConnected)
    }, [dataChannelConnected, onConnectionChange])

    useEffect(() => {
        sendDataRef.current = sendData
        if (sendData) sendRef.current(JSON.stringify({
            action: 'startDial'
        }))
    }, [sendData])

    useEffect(() => {
        if (sendData && lastMessage !== null) {
            const data = lastMessage
            // const data = JSON.parse(lastMessage)
            if(onChange.current) onChange.current(data)
            requestAnimationFrame(() => {
                setCurrentAngle(data.currentAngle)
            })
        }
    }, [sendData, lastMessage])

    return (
        show && <div className={styles.root}>
            <div className={styles.container}>
                <div className={clsx(styles.dialBox, {
                    [styles.disconnected]: !dataChannelConnected
                })}>
                    <Dial currentAngle={currentAngle} {...dialProps} />
                    <div className={styles.dialButton}>

                    </div>
                </div>
            </div>
        </div>
    )
})

export default DialViewer;
