import React, { useState, useRef, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HelpModal from './HelpModal';
import InfoMessage from './InfoMessage';
import YesNoQuestion from './YesNoQuestion';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        // width: '85%',
        // height: '85%'
    },
    stepper: {
        height: '75%',
        justifyContent: 'space-evenly'
    },
    label: {
        fontSize: '1rem',
    },
    spinner: {
        position: 'absolute',
        margin: '1rem'
    },
    error: {
        minHeight: 'calc(3rem + 6px)'
    }
})

const IntroChecker = React.memo(({ enableGeoRestriction, allowedCountries, enhancedGeoRestriction, subGeoRestrictionType, subGeoRestriction, geoData, onComplete, onError, noRetry }) => {
    const styles = useStyles();
    const [status, setStatus] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const [geoError, setGeoError] = useState()

    const {t} = useTranslation('self_tech_check_step_1');
    // const checkRef = useRef(onComplete)

    useEffect(() => {
        if (enableGeoRestriction && geoData) {
            if (!allowedCountries.includes(geoData.country)) setGeoError("Country")

            if (enhancedGeoRestriction) {
                const restriction = subGeoRestrictionType && (subGeoRestrictionType.charAt(0).toUpperCase() + subGeoRestrictionType.slice(1)).replace(/(\B[A-Z])/g, ' $1')
                if (!subGeoRestriction.includes(geoData[subGeoRestrictionType])) setGeoError(`${restriction}`)
            }
        } else {
            setGeoError()
        }
    }, [enableGeoRestriction, allowedCountries, enhancedGeoRestriction, subGeoRestrictionType, subGeoRestriction, geoData])

    const handleQuestionAnswer = useCallback((yes) => {

        if (yes && !geoError) {
            // const callback = status.questionIndex < checkQuestions.length - 1 ? () => {
            //     setStatus(prev => ({
            //         videoComplete: true,
            //         questionIndex: prev.questionIndex + 1
            //     }))
            // } : undefined
            onComplete()
        } else {
            const answerError = geoError ? new Error('Geo Restricted', { cause: { reason: geoError } }) : new Error('Wrong Device')
            onComplete(() => {
                setSubmitted(true)
                setStatus({
                    failed: true,
                    error: answerError
                })
                onError(answerError)
            })
        }
    }, [geoError, onComplete, onError])

    const helpComponents = {
        "Wrong Device": {
            message: t('switch_to_right_device')
        },
        "Geo Restricted": {
            message: ({ cause }) => t('geo_restricted', {reason: cause.reason}),
        }
    }

    return (
        <div className={styles.root}>
            <YesNoQuestion
                onAnswer={handleQuestionAnswer}
                question={t('device_check_question')}
                submitted={submitted}
            />
            <InfoMessage
                {...status}
                messages={helpComponents}
                defaultMessage={t('error_click_to_retry')}
            // noRetry={noRetry}
            />
        </div>
    )
})

export default IntroChecker;