import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Paper, CircularProgress, Stepper, Step, StepLabel, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Webcam from 'react-webcam';
import InfoMessage from './InfoMessage';
import * as workerTimers from 'worker-timers'
import * as faceapi from '@vladmandic/face-api';
import tfManifest from '@vladmandic/face-api/model/tiny_face_detector_model-weights_manifest.json'
import tfModel from '@vladmandic/face-api/model/tiny_face_detector_model.bin'
import frManifest from '@vladmandic/face-api/model/face_recognition_model-weights_manifest.json'
import frModel from '@vladmandic/face-api/model/face_recognition_model.bin'
import agManifest from '@vladmandic/face-api/model/age_gender_model-weights_manifest.json'
import agModel from '@vladmandic/face-api/model/age_gender_model.bin'
import HelpModal from './HelpModal';
import { useRetry, useSystemCheck, withNextRetry } from './hooks';
// const models = require('@vladmandic/face-api/model');
import avHelp from './resources/av-help.png'
import camHelp from './resources/cam-help.png'
import LoadingStepIcon from './LoadingStepIcon';
import MLWebcam from './MLWebcam';
import { useTranslation } from 'react-i18next'; 

const short = require('short-uuid');

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        // width: '85%',
        // height: '85%'
    },
    webcam: {
        // display: ({ hideVideo }) => hideVideo ? 'none' : 'block',
        height: '100%',
        width: '100%'
    },
    spinnerBox: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    spinner: {
        margin: '1rem'
    },
    label: {
        fontSize: '1rem',
    },
    infoBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 'calc(6.5rem + 6px)',
        // width: '85%'
    }
})

const WebcamChecker = React.memo(({ onComplete, onError, noRetry }) => {
    const [status, setStatus] = useState({})
    const [faceDeteced, setFaceDetected] = useState(false)
    const styles = useStyles();

    const detectionRef = useRef()
    const detectionTimeout = useRef()
    const {t} = useTranslation('self_tech_check_step_3');

    const helpComponents = {
        "Permission denied": {
            message: t('enable_camera_permissions'),
            slides: [
                {
                    text: t('locate_button_in_URL_and_click'),
                    img: avHelp
                },
                {
                    text: t('select_always_allow'),
                    img: camHelp
                },
                {
                    text: t('close_guide_and_retry'),
                    // img: camHelp
                }
            ]
        },
        "Failed to Detect": {
            message: t('failed_to_detect')
        }
    }

    useEffect(() => {
        return () => {
            if (detectionTimeout.current) workerTimers.clearTimeout(detectionTimeout.current)
        }
    }, [])

    useEffect(() => {
        if (faceDeteced) {
            setStatus({
                checkStep: 'webcam',
                completed: true,
                detectionOn: false,
                hideInfo: true
            })
            console.log('DETECTIONS:', detectionRef.current.size)
            if (detectionTimeout.current) {
                workerTimers.clearTimeout(detectionTimeout.current)
                detectionTimeout.current = null
            }
            const ageGender = {
                ageMin: 100,
                ageMax: 0,
                gender: '',
                genderProbability: 0
            }
            detectionRef.current.forEach(detection => {
                ageGender.ageMin = Math.min(ageGender.ageMin, detection.age)
                ageGender.ageMax = Math.max(ageGender.ageMax, detection.age)
                if (detection.genderProbability > ageGender.genderProbability) {
                    ageGender.gender = detection.gender
                    ageGender.genderProbability = detection.genderProbability
                }
            })
            console.log('ESTIMAGED INFO: ', ageGender)
            onComplete()
        }
    }, [faceDeteced, onComplete])

    const handleWebcamCheck = useCallback(() => {
        setStatus({
            checkStep: 'webcam',
            active: true,
            detectionOn: true,
            hideInfo: true
        })
        const onAddDetection = () => {
            if (detectionRef.current.size >= 10) {
                detectionRef.current.removeEventListener('add', onAddDetection)
                setFaceDetected(true)
            }
        }
        detectionRef.current.addEventListener('add', onAddDetection)
        detectionTimeout.current = workerTimers.setTimeout(() => {
            const error = new Error("Failed to Detect")
            setStatus({
                checkStep: 'webcam',
                failed: true,
                error
            })
            detectionRef.current.removeEventListener('add', onAddDetection)
            detectionTimeout.current = null
            if (onError) onError(error, () => {
                handleWebcamCheck()
            })
        }, 15000) // 15 SECOND DETECTION TIMEOUT
    }, [onError])

    const handleLoaded = useCallback(() => {
        setStatus({
            info: t('click_next_to_start_webcam')
        })
        onComplete(() => {
            handleWebcamCheck()
        })
    }, [onComplete, handleWebcamCheck, t])

    const handleError = useCallback((error, resetCallback) => {
        setStatus({
            failed: true,
            error
        })
        if (onError) onError(error, () => {
            setStatus({})
            resetCallback()
        })
    }, [onError])

    return (
        <div className={styles.root}>
            <MLWebcam
                withAudio={false}
                withDetection={status.detectionOn}
                detectionInterval={200}
                detections={detectionRef}
                onUserMedia={handleLoaded}
                onUserMediaError={handleError}
            />
            <div className={styles.infoBox}>
                {status.checkStep && <Step>
                    <StepLabel
                        classes={{ label: styles.label }}
                        StepIconComponent={LoadingStepIcon}
                        active={status.active}
                        error={status.failed}
                        completed={status.completed}
                    >
                        {t('checking_webcam')}
                    </StepLabel>
                </Step>}
                <InfoMessage
                    {...status}
                    messages={helpComponents}
                    defaultMessage={t('issue_with_cam_and_mic')}
                    noRetry={noRetry}
                />
            </div>
        </div>
    )
})

export default WebcamChecker;