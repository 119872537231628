import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'

const useStyles = makeStyles({
    titleBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem',
        visibility: ({ hidden }) => hidden ? 'hidden' : 'visible'
    }
})

const SessionInfoBanner = React.memo(({
    videoTitle,
    sessionDate,
    hidden,
    ...props
}) => {
    const styles = useStyles({ hidden });

    return (
        <div className={styles.titleBox}>
            <Typography variant="h3">{videoTitle}</Typography>
            <Typography variant="h5">{moment(sessionDate).format('MMMM Do YYYY, h:mm a')}</Typography>
        </div>
    )

})

export default SessionInfoBanner;