import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
// import moment from 'moment'
// // import seasiLogo from './resources/seasi-logo.jpg'
// import seasiLogo from './resources/seasi-logo.png'
// import pfmLogo from './resources/pfm-logo.png'
// import vvtvLogo from './resources/vv-logo.png'
// import ewLogo from './resources/ew-logo.png'
// import background from './resources/black-bg.jpg'
import BrandedPage from './BrandedPage'
import LoadingPage from './LoadingPage';
import config from './config.json'
import SessionInfoBanner from './SessionInfoBanner';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    fullscreen: {
        width: '100vw',
        height: '100vh',
        overflow: 'hidden'
    },
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        // marginTop: 'auto',
        minHeight: '68%',
        height: 'auto',
        marginBottom: 'auto'
        // width: '55%',
        // minWidth: '400px',
        // maxWidth: '800px',
        // minHeight: '300px'
    }
})

const guestCompletePromise = (guestId, sessionId, requestId) =>
    fetch(`https://${config.rest.sessionGuestAPI}/complete`, {
        method: 'POST',
        mode: 'cors',
        // credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ guestId, sessionId, requestId })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

const CompletePage = React.memo(({ location: { search, state }, history }) => {
    const [loading, setLoading] = useState(true)
    const [reloading, setReloading] = useState(false)
    const [valid, setValid] = useState(false)
    const [techCheck, setTechCheck] = useState(false)
    const [videoTitle, setVideoTitle] = useState()
    const [sessionDate, setSessionDate] = useState()
    const [sessionTeam, setSessionTeam] = useState()
    const styles = useStyles();
    const {t} = useTranslation('self_tech_check_step_6');

    const params = queryString.parse(search);

    const { sessionid: sessionId, id: userId, requestid: requestId } = state || params //take a look later

    console.log(sessionId, userId, requestId)

    useEffect(() => {
        if (!sessionId || !userId) {
            history.push('/')
        } else {
            if ((state && state.redirect)) {
                setSessionTeam(state.team)
                setSessionDate(state.scheduledStartTime)
                setVideoTitle(state.videoTitle)
                setTechCheck(state.techCheck)
                setReloading(state.returning)
                setValid(true)
                setLoading(false)
            } else {
                guestCompletePromise(userId, sessionId, requestId).then((res) => {
                    console.log(res)
                    setSessionTeam(res.team)
                    setSessionDate(res.scheduledStartTime)
                    setVideoTitle(res.videoTitle)
                    setTechCheck(res.techCheck)
                    setReloading(res.returning)
                    setValid(true)
                    setLoading(false)
                }).catch((e) => {
                    console.log(e)
                    history.push('/')
                })
            }
        }
    }, [history, requestId, sessionId, state, userId])

    return (
        <div className={styles.fullscreen}>
            <div className={styles.root}>
                {loading && <LoadingPage />}
                {(!loading && valid) && <BrandedPage team={sessionTeam}>
                    <SessionInfoBanner videoTitle={videoTitle} sessionDate={sessionDate} />
                    <div className={styles.container}>
                        <Typography variant="h4">{t('thanks_for_viewing')}</Typography>
                        <br />
                        <Typography variant="h6">
                            {techCheck ? t('tech_check_portion_completed') : !reloading ? t('close_tab_and_return_to_session') : t('join_us_again')}
                        </Typography>
                    </div>
                </BrandedPage>}
            </div>
        </div>
    )
})

export default CompletePage;
