import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flex: 1,
        // width: '100%',
        // height: '100%'
    },
    title: {
        flex: 1,
        display: 'flex',
        alignItems: 'flex-end',
        padding: '1rem'
    },
    body: {
        textAlign: 'center',
        flex: 2,
        padding: '2rem'
        // flexBasis: '40%'
    },
    spacing: {
        marginBottom: '1rem'
    }
})

const renderMessage = (message) => {
    if (message instanceof Function) {
        const Component = message
        return <Component />
    } else {
        return message || "\u00a0"
    }
}

const CheckInstructions = React.memo(({ title, instructions = [] }) => {
    const styles = useStyles();

    return (
        <div className={styles.root}>
            <Typography variant="h4" className={styles.title}>{title}</Typography>
            {Array.isArray(instructions) ? <div className={styles.body}>
                {instructions.map((inst, i) => <Typography key={`instructions${i}`} variant="body1" className={styles.spacing}>{renderMessage(inst)}</Typography>)}
            </div> : <Typography variant="body1" className={styles.body}>
                {renderMessage(instructions)}
            </Typography>}
        </div>
    )
})

export default CheckInstructions;