import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import App from './App';
// import AppTest from './AppTest';
import ErrorPage from './ErrorPage'
import CompletePage from './CompletePage'
import EjectPage from './EjectPage';
import RegistrationPage from './RegistrationPage';
import VerificationPage from './VerificationPage';
import CancelPage from './CancelPage';
import EventFullPage from './EventFullPage';
import { useTranslation } from 'react-i18next';
import { SnackbarProvider } from 'notistack';

const AppRoutes = React.memo((props) => {
    console.log('render routes', props)
    const { t } = useTranslation('translation');

    return (
        // <div style={{
        //     width: '100vw',
        //     height: '100vh'
        // }}>
        <SnackbarProvider maxSnack={10} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }} autoHideDuration={6000}>
            <Switch>
                <Route exact path={['/session/:sessionId', '/session/:sessionId/:requestId', '/techcheck/:sessionId']} component={App} />
                <Route exact path='/register/:sessionId' component={RegistrationPage} />
                <Route exact path='/verify/:guestId' component={VerificationPage} />
                <Route exact path='/cancel/:guestId' component={CancelPage} />
                <Route exact path='/complete' component={CompletePage} />
                <Route exact path='/eject' component={EjectPage} />
                <Route exact path='/full' component={EventFullPage} />
                {/* <Route exact path='/error' component={ErrorPage} /> */}
                <Route render={({ location: { state = {} } }) => {
                    const { error = t('error_not_found'), message = t('error_page_not_found'), logo = "PFM" } = state
                    return <ErrorPage error={error} message={message} logo={logo} />
                }} />
            </Switch>
        </SnackbarProvider>
        // </div>
    )
})

export default withRouter(AppRoutes);