import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Slider, Button } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { useLocalStorage } from './hooks';
import { GameControllerThumb, MouseEarsThumb, MovieReelThumb, MovieThumb, NetflixThumb, NintendoLogoThumb, NintendoNetworkThumb, NintendoSwitchThumb, RiotGamesThumb, EpicGamesThumb, DCThumb, WBThumb, HuluThumb, PacmanThumb, PlaystationThumb, XboxThumb, AmazonThumb, FootballThumb, BasketballThumb, SoccerBallThumb, } from './SliderKnobs'
import * as workerTimers from 'worker-timers';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    root: {
        // position: 'absolute',
        // top: 0,
        // width: '100vw',
        // height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1001,
        overflow: 'hidden'
    },
    container: {
        // position: 'fixed',
        bottom: 0,
        width: '100%',
        // height: ({ ratio }) => ratio ? `${ratio * 100}vw` : '100%',
        flex: 1,
        minHeight: '100px',
        maxHeight: ({ ratio }) => ratio ? `calc((100vh - ${ratio * 100}vw) / 2)` : '100%',
        // maxHeight: '100%',
        // maxWidth: ({ ratio }) => ratio ? `${1/ratio * 100}vh` : '100%',
        // margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        // opacity: 0.45,
        filter: 'brightness(1.75) drop-shadow(0px 0px 1px white)',
        border: '1px solid transparent',
        // paddingBottom: '14px',
        boxSizing: 'border-box'
    },
    sliderBox: {
        width: '70%',
    },
    spacer: {
        display: 'flex',
        flex: 1
    },
    sliderButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '12px',
        marginBottom: '5px'
    },
    button: {
        margin: '0 5px 0 5px',
        '&.MuiButton-contained.Mui-disabled': {
            color: 'rgba(255,255,255, .2) !important'
        }
    },
    thumb: {
        color: ({ sliderKnobColor }) => sliderKnobColor || 'black'
    },
    // sliderRoot: {
    //     '& .MuiSlider-mark[data-index="1"]': {
    //         // transform: "translateX(-100%)"
    //         height: '50%',
    //     }
    // }
    centerMark: {
        height: '60%',
        width: '1px',
        top: '20%',
        position: 'absolute',
        left: '50%',
        backgroundColor: '#bfbfbf',
        zIndex: -1
    },
    alert: {
        position: 'absolute',
        top: 0,
        padding: '10px',
        transform: 'translateY(-100%)'
    }
})

const knobMap = {
    'Clapperboard': MovieThumb,
    'Film Reel': MovieReelThumb,
    'Netflix': NetflixThumb,
    'Hulu': HuluThumb,
    'Warner Bros': WBThumb,
    'Amazon': AmazonThumb,
    'DC': DCThumb,
    'Mickey Ears': MouseEarsThumb,
    'Game Controller': GameControllerThumb,
    'Pacman': PacmanThumb,
    'Xbox': XboxThumb,
    'Playstation': PlaystationThumb,
    'Nintendo': NintendoLogoThumb,
    'Nintendo Switch': NintendoSwitchThumb,
    'Nintendo Network': NintendoNetworkThumb,
    'Epic Games': EpicGamesThumb,
    'Riot Games': RiotGamesThumb,
    'Football': FootballThumb,
    'Basketball': BasketballThumb,
    'Soccer Ball': SoccerBallThumb
}

const EmbeddedSlider = React.memo(({ sessionId, userId, techCheck, videoIndex, includeShoutOuts, sendData, show, viewRatio, onScoreChange, maxScore, dialMotion, numberOfMeters, sliderKnob, sliderKnobColor, includeTuneOut, includeBreaks, breakCount, maxBreaks, setBreakCount, noDialArrowKeys, inactiveReminder, inactiveInterval, inactiveMessage, reset, ...sliderProps }) => {
    const styles = useStyles({ ratio: viewRatio, sliderKnobColor, numberOfMeters });
    const [currentValue, setCurrentValue] = useLocalStorage(`sliderscore${sessionId}-${videoIndex || 0}-${techCheck ? '-TC' : ''}`, maxScore / 2)
    const [buttonPress, setButtonPress] = useLocalStorage(`tuneinout${sessionId}-${videoIndex || 0}-${techCheck ? '-TC' : ''}`, undefined);
    const [lastBtn, setLastBtn] = useState();
    const [inactive, setInactive] = useState(false);
    const inactivityTimeout = useRef();
    const {t} = useTranslation('buttons');

    const activityHandler = useCallback(async () => {
        console.log('CLEARING INACTIVITY')
        setInactive(false)
        if (inactivityTimeout.current) workerTimers.clearTimeout(inactivityTimeout.current)
        inactivityTimeout.current = null;
    }, [])

    const inactivityHandler = useCallback(() => {
        console.log('SETTING INACTIVITY')
        inactivityTimeout.current = workerTimers.setTimeout(() => {
            setInactive(true)
        }, inactiveInterval * 1000)
    }, [inactiveInterval])

    useEffect(() => {
        return () => {
            if (inactivityTimeout.current) workerTimers.clearTimeout(inactivityTimeout.current)
        }
    }, [])

    const onChange = useRef()
    onChange.current = onScoreChange

    const max = useRef()
    max.current = maxScore

    const change = useRef(0)

    const marks = useMemo(() => {
        if (numberOfMeters) {
            const m = []
            m.push({ value: 0 })
            for (let i = 1; i <= numberOfMeters - 2; i++) {
                m.push({ value: maxScore / (numberOfMeters - 1) * i })
            }
            m.push({ value: maxScore })
            return m
        } else {
            return undefined
        }
    }, [numberOfMeters, maxScore])

    useEffect(() => {
        if (show && (sendData || techCheck) && inactiveReminder) {
            inactivityHandler()
        } else {
            activityHandler()
        }
    }, [show, sendData, techCheck, inactiveReminder, activityHandler, inactivityHandler])

    useEffect(() => {
        onChange.current = onScoreChange
    }, [onScoreChange])

    useEffect(() => {
        max.current = maxScore
    }, [maxScore])

    useEffect(() => {
        if (sendData && onChange.current) {
            onChange.current({
                score: Math.round(currentValue),
                button: lastBtn,
                timestamp: Date.now(),
                registrationId: `${userId}`,
                sessionId: `${sessionId}`,
            })
            activityHandler().then(inactiveReminder ? inactivityHandler : () => {})
        }
        setLastBtn()
    }, [sendData, sessionId, userId, inactiveReminder, currentValue, activityHandler, inactivityHandler, lastBtn, setLastBtn])

    useEffect(() => {
        if (reset) {
            console.log('RESETTING DIAL AND TUNE OUT')
            setCurrentValue(maxScore / 2)
            setButtonPress(undefined)
        }
    }, [reset, maxScore, setCurrentValue, setButtonPress])

    // const scrollHandler = ({ deltaY, deltaX }) => {
    //     requestAnimationFrame(() => {
    //         change.current += (deltaY - deltaX)
    //         const up = Math.sign(change.current) === 1
    //         const round = up ? Math.ceil : Math.floor
    //         setCurrentValue(prev => {
    //             let newVal = prev + change.current
    //             if (dialMotion === 'Stepwise') {
    //                 const prevRounded = round((prev) / (maxScore / (numberOfMeters - 1))) * (maxScore / (numberOfMeters - 1))
    //                 const roundedVal = round((prev + change.current) / (maxScore / (numberOfMeters - 1))) * (maxScore / (numberOfMeters - 1))
    //                 if ((up && newVal > prevRounded) || (!up && newVal < prevRounded)) {
    //                     newVal = roundedVal
    //                     change.current = 0
    //                 } else {
    //                     newVal = prev
    //                 }
    //             }
    //             return newVal > maxScore ? maxScore : newVal <= 0 ? 0 : newVal
    //         })
    //     })
    // }

    // useEventListener('wheel', scrollHandler, false) //TODO: DON'T ADD UNTIL STARTED

    // useEffect(() => {
    //     if (sendData) {
    //         window.addEventListener('wheel', scrollHandler, { passive: false })
    //         return () => {
    //             window.removeEventListener('wheel', scrollHandler,  { passive: false })
    //         }
    //     }
    // }, [sendData])

    const handleKeyEvent = useCallback((key, event) => {
        event.preventDefault()
        if (sendData || techCheck) {
            if (["up", "right"].includes(key)) {
                setCurrentValue(prev => {
                    const newVal = dialMotion === 'Stepwise' ? Math.ceil((prev + 1) / (maxScore / (numberOfMeters - 1))) * (maxScore / (numberOfMeters - 1)) : prev + 1
                    return newVal > maxScore ? maxScore : newVal <= 0 ? 0 : newVal
                })
            } else if (["down", "left"].includes(key)) {
                setCurrentValue(prev => {
                    const newVal = dialMotion === 'Stepwise' ? Math.floor((prev - 1) / (maxScore / (numberOfMeters - 1))) * (maxScore / (numberOfMeters - 1)) : prev - 1
                    return newVal > maxScore ? maxScore : newVal <= 0 ? 0 : newVal
                })
            }
        }
    }, [sendData, techCheck, dialMotion, maxScore, numberOfMeters, setCurrentValue])

    const handleSliderChange = useCallback((e, v) => {
        if (!e.type.includes('key')) setCurrentValue(v)
    }, [setCurrentValue])

    const handleTuneOut = useCallback((event) => {
        event.currentTarget.blur()
        if ((sendData || techCheck) && buttonPress !== 'R') {
            setButtonPress('R')
            setLastBtn('R');
        }
    }, [sendData, techCheck, buttonPress, setButtonPress, setLastBtn])

    const handleShoutOut = useCallback((event) => {
        event.currentTarget.blur();
        setLastBtn('S');
    }, [setLastBtn])

    const handleBreak = useCallback((event) => {
        event.currentTarget.blur();
        setLastBtn('B-S');
        setBreakCount(prev => prev++);
    }, [setLastBtn, setBreakCount])

    return (
        show && <>
            <KeyboardEventHandler handleFocusableElements={true} isDisabled={noDialArrowKeys} handleKeys={["up", "down", "left", "right"]} onKeyEvent={handleKeyEvent} />
            <div className={styles.container}>
                <div className={styles.sliderBox}>
                    <div style={{ position: 'relative' }}>
                        <GameSlider
                            disabled={!(sendData || techCheck)}
                            value={currentValue}
                            track={false}
                            marks={marks}
                            min={0}
                            max={maxScore}
                            step={dialMotion === 'Stepwise' ? null : undefined}
                            onChange={handleSliderChange}
                            {...sliderProps}
                            ThumbComponent={knobMap[sliderKnob]}
                            classes={{
                                // root: styles.sliderRoot,
                                thumb: styles.thumb,
                                // mark: styles.mark,
                                // markActive: styles.markActive
                            }}
                        />
                        <div className={styles.centerMark} />
                    </div>
                    {(includeTuneOut || includeShoutOuts || includeBreaks) && <div className={styles.sliderButtons}>
                        {includeTuneOut && <Button className={styles.button} variant="contained" size="small" color="primary" disabled={!((sendData && buttonPress !== 'R') || techCheck)} style={{
                            border: `1px solid rgba(255,255,255,${buttonPress === 'R' ? '0.85' : '0.15'})`,
                            // backgroundColor: buttonPress === 'R' ? '#E15554' : '#9d3b3a',
                            backgroundColor: '#9d3b3a',
                            // filter: !(sendData || techCheck) ? 'grayscale(1)' : `contrast(${buttonPress === 'R' ? '2.5' : '1'}) grayscale(${buttonPress === 'R' ? '0' : '0.35'})`
                        }} onClick={handleTuneOut}>{t('TUNE_OUT_button')}</Button>}
                        {includeBreaks && <Button className={styles.button} variant="contained" size="small" color="primary" disabled={!(breakCount < maxBreaks)} style={{
                            border: `1px solid rgba(255,255,255,${!(breakCount < maxBreaks) ? '0.85' : '0.15'})`,
                            // backgroundColor: buttonPress === 'R' ? '#E15554' : '#9d3b3a',
                            backgroundColor: 'rgba(255, 255, 0, 0.5)',
                            // filter: `contrast(${!(breakCount < maxBreaks) ? '2.5' : '1'}) grayscale(${!(breakCount < maxBreaks) ? '0' : '0.35'})`
                        }} onClick={handleBreak}>{t('BREAK_btn')}</Button>}
                        {includeShoutOuts && <Button className={styles.button} variant="contained" size="small" color="primary" style={{
                            border: `1px solid rgba(255,255,255, 0.15)`,
                            // backgroundColor: buttonPress === 'R' ? '#E15554' : '#9d3b3a',
                            backgroundColor: 'rgba(59, 175, 44, 0.42)',
                            // filter: `contrast(${buttonPress === 'R' ? '2.5' : '1'}) grayscale(${buttonPress === 'R' ? '0' : '0.35'})`
                        }} onClick={handleShoutOut}>{t('COMMENT_btn')}</Button>}
                        {/* <Button className={styles.button} variant="contained" size="small" color="primary" style={{
                            border: `1px solid rgba(255,255,255,${buttonPress === 'G' ? '0.85' : '0.15'})`,
                            // backgroundColor: buttonPress === 'G' ? '#3BB273' : '#297c50',
                            backgroundColor: '#297c50',
                            filter: `contrast(${buttonPress === 'G' ? '2.5' : '1'}) grayscale(${buttonPress === 'G' ? '0' : '0.35'})`
                        }} onClick={() => {
                            if (sendData && buttonPress !== 'G') setButtonPress('G')
                        }}>TUNE IN</Button> */}
                    </div>}
                </div>
                <div className={styles.alert}>
                    {/* <Snackbar open={inactive}> */}
                    {inactive && <MuiAlert elevation={0} variant="filled" severity="error">
                        {inactiveMessage || t('continue_moving_dial_msg')}
                    </MuiAlert>}
                    {/* </Snackbar> */}
                </div>
            </div>
        </>
    )
})

const GameSlider = withStyles({
    root: {
        //   color: '#3a8589',
        //   height: 3,
        //   padding: '13px 0',
    },
    disabled: {
        // height: 'auto',
        // width: 'auto',
        // transform: 'scale(1.25)',
        filter: 'grayscale(1)'
    },
    thumb: {
        backgroundColor: 'inherit',
        // height: 'auto',
        // width: 'auto',
        transform: 'scale(1.25)'
        // minHeight: 22,
        // minWidth: 22,
        // height: '80%',
        // width: 27,
        //   backgroundColor: '#fff',
        //   border: '1px solid currentColor',
        //   marginTop: -12,
        //   marginLeft: -13,
        //   boxShadow: '#ebebeb 0 2px 2px',
        //   '&:focus, &:hover, &$active': {
        //     boxShadow: '#ccc 0 2px 3px 1px',
        //   },
        //   '& .bar': {
        //     // display: inline-block !important;
        //     height: 9,
        //     width: 1,
        //     backgroundColor: 'currentColor',
        //     marginLeft: 1,
        //     marginRight: 1,
        //   },
    },
    active: {},
    track: {
        background: 'white',
        // opaicty: 0.38
        //   height: 3,
    },
    rail: {
        background: 'linear-gradient(to right, #d2190b 5%, #f46336 30%, #ffe44d 50%, #88f435 60%, #02f514 95%)',
        opacity: 1,
        height: '10%'
        //   color: '#d8d8d8',
        //   opacity: 1,
        //   height: 3,
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: '25%',
        width: 1,
        top: '40%',
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(Slider);

export default EmbeddedSlider;
