import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import LoadingPage from './LoadingPage';
import config from './config.json'
import BrandedPage from './BrandedPage';
import SessionInfoBanner from './SessionInfoBanner';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    fullscreen: {
        width: '100vw',
        height: '100vh',
        overflow: 'hidden'
    },
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        // marginTop: 'auto',
        minHeight: '68%',
        height: 'auto',
        marginBottom: 'auto'
        // width: '55%',
        // minWidth: '400px',
        // maxWidth: '800px',
        // minHeight: '300px'
    }
})

const cancelGuestPromise = (guestId, token) =>
    fetch(`https://${config.rest.sessionGuestAPI}/cancel/${guestId}`, {
        method: 'POST',
        mode: 'cors',
        // credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

const CancelPage = React.memo(({ location: { search }, match: { params: { guestId } }, history }) => {
    const [loading, setLoading] = useState(true)
    const [sessionData, setSessionData] = useState()
    const styles = useStyles();

    const { id } = queryString.parse(search);
    const {t} = useTranslation('self_tech_check_step_6');

    useEffect(() => {
        if (!guestId || !id) {
            history.push('/')
        } else {
            cancelGuestPromise(guestId, id).then((res) => {
                console.log(res)
                setSessionData(res)
                setLoading(false)
            }).catch((e) => {
                console.log(e)
                history.push('/')
            })
        }
    }, [history, guestId, id])

    return (
        <div className={styles.fullscreen}>
            <div className={styles.root}>
                {loading && <LoadingPage />}
                {!(loading) && <BrandedPage team={sessionData.team}>
                    <SessionInfoBanner videoTitle={sessionData.videoTitle} sessionDate={sessionData.guestCheckInTime} />
                    <div className={styles.container}>
                        <div className={styles.titleBox}>
                            <Typography variant="h4">{t('registration_cancelled')}</Typography>
                            <br />
                            <Typography variant="h6">{t('sorry_youre_unable')}</Typography>
                        </div>
                    </div>
                </BrandedPage>}
            </div>
        </div>
    )
})

export default CancelPage;
