import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import YesNoQuestion from './YesNoQuestion';
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        // width: '85%',
        // height: '85%'
    },
    stepper: {
        height: '75%',
        justifyContent: 'space-evenly'
    },
    label: {
        fontSize: '1rem',
    },
    spinner: {
        position: 'absolute',
        margin: '1rem'
    },
    error: {
        minHeight: 'calc(3rem + 6px)'
    }
})

// const helpComponents = {
//     "Can Not Attend": {
//         message: "Please switch to the device you plan to use on the day of the event and then re-open the link you were emailed to restart your tech check."
//     }
// } // not in use

const timezone = moment.tz(moment.tz.guess()).zoneAbbr()

const EventConfirmChecker = React.memo(({ checkInTime, onComplete, onError, noRetry }) => {
    const styles = useStyles();
    const [status, setStatus] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const {t} = useTranslation('self_tech_check_step_6');

    const checkInDateStr = new Date(checkInTime).toLocaleString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    const checkInTimeStr = new Date(checkInTime).toLocaleString('en-us', { timeStyle: 'short' })

    const handleQuestionAnswer = useCallback((yes) => {
        if (yes) {
            // const callback = status.questionIndex < checkQuestions.length - 1 ? () => {
            //     setStatus(prev => ({
            //         videoComplete: true,
            //         questionIndex: prev.questionIndex + 1
            //     }))
            // } : undefined
            onComplete()
        } else {
            const answerError = new Error('Can Not Attend')
            onComplete(() => {
                setStatus({
                    failed: true,
                    error: answerError
                })
                onError(answerError, () => {
                    setSubmitted(true)
                })
            })
        }
    }, [onComplete, onError])

    return (
        <div className={styles.root}>
            <YesNoQuestion
                onAnswer={handleQuestionAnswer}
                question={t('event_info', {date: checkInDateStr, time: checkInTimeStr, timezone: timezone})}
                yesLabel={t('SIGN_ME_UP_button')}
                noLabel={t('CHANGED_MY_MIND_button')}
                submitted={submitted}
            />
            {/* <InfoMessage
                {...status}
                messages={helpComponents}
                defaultMessage="There was an error. Please click below to retry."
                // noRetry={noRetry}
            /> */}
        </div>
    )
})

export default EventConfirmChecker;