import React, { useEffect, useCallback, useState, useRef } from 'react'
import { Paper, Fab, Zoom, Grow, Badge } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import ChatIcon from '@material-ui/icons/Chat';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import ChimeChat from './ChimeChat';

const useStyles = makeStyles({
    chatPanel: {
        // position: 'absolute',
        // top: 0,
        // left: 0,
        width: 'calc(200px + (650 - 200) * ((100vw - 100px) / (2400 - 100)))',
        height: '100%',
        margin: '1.5rem',
        position: 'relative',
        zIndex: 5,
        overflow: 'hidden'
    },
    chatPopup: {
        position: 'absolute',
        bottom: '0',
        right: '0',
        // zIndex: '10',
        // padding: '1rem',
        // boxSizing: 'content-box'
        height: '100%',
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'flex-end'
    },
    chatFabBox: {
        margin: '1rem',
        position: 'relative'
    },
    chatFab: {
        position: 'relative',
        zIndex: 5,
        opacity: 0.5,
        '&:hover': {
            opacity: 1
        }
    },
    // toastBackgroundColor: {
    //     backgroundColor: '#000000'
    // },
    // toastTextColor: {
    //     color: '#000000'
    // },
    // toastIconColor: {
    //     color: '#000000 !important'
    // }
});

const ChatPopup = React.memo(({ show = true, openWithChat = false, isSessionFilesOpen, theme, displayChat, ...props }) => {
    const styles = useStyles()

    const [open, setOpen] = useState(openWithChat)
    const [notification, setNotification] = useState(0)
    // const [alertOpen, setAlertOpen] = useState(false)
    // const alertOpenRef = useRef(false)

    // useEffect(() => {
    //     alertOpenRef.current = alertOpen
    // }, [alertOpen])

    useEffect(() => {
        if (open) setNotification(0)
    }, [open])

    useEffect(() => {
        if (isSessionFilesOpen === true) setOpen(false)
    }, [isSessionFilesOpen])

    const togglePanel = useCallback(() => {
        setOpen(prev => !prev)
    }, [])

    const showNotification = useCallback((newMessages) => {
        if (!open) setNotification(newMessages)
    }, [open])

    // const handleAlertClear = useCallback(() => {
    // }, [])

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    return (
        <>
            <div className={styles.chatPopup}>
                <div className={styles.chatFabBox}>
                    <Zoom
                        key={'chime-chat-fab'}
                        in={show}
                        timeout={transitionDuration}
                        unmountOnExit
                    >
                        <Fab style={{display: displayChat ? 'block' : 'none'}} color="primary" className={styles.chatFab} onClick={togglePanel}>
                            <Badge color="secondary" badgeContent={notification}  max={99}>
                                {open ? <SpeakerNotesOffIcon /> : <ChatIcon />}
                            </Badge>
                        </Fab>
                    </Zoom>
                </div>
                <Grow
                    in={open}
                    style={{ transformOrigin: 'bottom' }}
                >
                    <Paper
                        className={styles.chatPanel}
                        elevation={3}
                    >
                        <ChimeChat onChannelMessage={showNotification} visible={open} setOpen={setOpen} {...props} />
                    </Paper>
                </Grow>
            </div>
        </>
    );

})

export default withTheme(ChatPopup)