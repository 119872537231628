import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import moment from 'moment'
import seasiLogo from './resources/seasi-logo.png'
import pfmLogo from './resources/pfm-logo.png'
import vvtvLogo from './resources/vv-logo.png'
import ewLogo from './resources/ew-logo.png'
import background from './resources/black-bg.jpg'
import ReactCodeInput from 'react-code-input';
import CountdownDisplay from './CountdownDisplay';
import TriviaQuestions from './TriviaQuestions';
import BrandedPage from './BrandedPage';
import SessionInfoBanner from './SessionInfoBanner';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    root: {
        height: '100%',
        width: '100%',
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        // background: `url(${background})`,
        // backgroundSize: 'cover',
        // background: 'rgba(21,27,23)',
        background: 'black',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 1
    },
    background: {
        background: `url(${background})`,
        // background: 'rgba(21,27,23)',
        backgroundSize: 'cover',
        flex: 1
    },
    logoBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    teamLogo: {
        height: '100%',
        maxHeight: '150px'
    },
    headerLogo: {
        height: '100%',
        maxHeight: '80px'
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        width: '55%',
        minWidth: '400px',
        maxWidth: '800px',
        minHeight: '300px'
    },
    continue: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 2rem 2rem 2rem',
        zIndex: 1002,
    },
    continueBack: {
        zIndex: 1001,
        position: 'absolute',
        height: '100%',
        width: '100%',
        backdropFilter: 'blur(12px)'
    },
    appbar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem 2rem 1rem 2rem',
        boxSizing: 'border-box',
        zIndex: 1000
    },
    fixedHeader: {
        position: 'static',
        top: 0,
        width: '100%',
        height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    fixedFooter: {
        position: 'static',
        bottom: 0,
        width: '100%',
        // height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    white: {
        color: 'white'
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem'
    },
    margin: {
        margin: '1rem'
    },
    buttonRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    buttonBox: {
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        height: '100%',
        width: '95%',
        margin: 'auto',
        // minWidth: '300px',
        // maxWidth: '800px',
        color: 'white',
        position: 'relative'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        // marginTop: 'auto',
        minHeight: '68%',
        height: 'auto',
        marginBottom: 'auto'
        // width: '55%',
        // minWidth: '400px',
        // maxWidth: '800px',
        // minHeight: '300px'
    }
})

const logos = {
    'SEA': seasiLogo,
    'PFM': pfmLogo,
    'VTV': vvtvLogo,
    'EWX': ewLogo,
    'CPT': seasiLogo,
}

const PreContentPresenter = React.memo(({
    ready,
    showPreSurvey,
    surveyRequestId,
    techCheck,
    focusGroup,
    smsSent,
    smsError,
    resend,
    verified2FA,
    error2FA,
    sessionStarted,
    sessionId,
    scheduledStartTime,
    videoTitle,
    team,
    isBetween,
    showCountdown,
    countdownOffset,
    showQuiz,
    include2fa,
    override2fa,
    isResuming,
    rtcDial,
    dialLinkSent,
    dialLinkError,
    resendDial,
    dialConnected,
    showDialConnection,
    goToSurvey,
    handleSendDial,
    handleSendSMS,
    handleVerifySMS,
    handleReady,
    hidden
}) => {
    const styles = useStyles();

    const [authCode, setAuthCode] = useState('')
    const [waitingSMS, setWaitingSMS] = useState()
    const [waitingVerify, setWaitingVerify] = useState()
    const [waitingDial, setWaitingDial] = useState()

    const codeRef = useRef()
    const codeInputRef = useRef()

    useEffect(() => {
        codeRef.current = authCode
    }, [authCode])

    useEffect(() => {
        if (dialLinkSent || dialLinkError || resendDial) {
            setWaitingDial(false)
        }
    }, [dialLinkSent, dialLinkError, resendDial])

    useEffect(() => {
        if (smsError || smsSent || resend) {
            setWaitingSMS(false)
        }
    }, [smsError, smsSent, resend])

    useEffect(() => {
        if (error2FA || (verified2FA && typeof verified2FA.isVerified === 'boolean')) {
            setWaitingVerify(false)
        }
    }, [error2FA, verified2FA])

    const sendDial = useCallback(() => {
        setWaitingDial(true)
        if (handleSendDial) handleSendDial()
    }, [handleSendDial])

    const sendSMS = useCallback(() => {
        setWaitingSMS(true)
        if (handleSendSMS) handleSendSMS()
    }, [handleSendSMS])

    const verifySMS = useCallback(() => {
        setWaitingVerify(true)
        if (handleVerifySMS) handleVerifySMS(codeRef.current)
    }, [handleVerifySMS])

    const clearAuthInput = useCallback(() => {
        if (codeInputRef.current.textInput[0]) codeInputRef.current.textInput[0].focus()
        codeInputRef.current.state.input.forEach((v, i, a) => a[i] = '')
    }, [])

    const { t } = useTranslation(['session_video', 'buttons', 'pre_survey', 'dial_link']);

    return (
        <BrandedPage team={team}>
            {!hidden && <>
                <SessionInfoBanner videoTitle={videoTitle} sessionDate={scheduledStartTime} />
                <div className={styles.container}>
                    {((include2fa && !override2fa) && !((verified2FA && verified2FA.isVerified))) ?
                        <>
                            {(!smsSent) ?
                                <>
                                    <Typography variant="h6">{t('click_to_send_VT_auth_code', { ns: 'session_video' })}</Typography>
                                    <div className={styles.buttonBox}>
                                        <Button className={styles.margin} disabled={waitingSMS} color='primary' variant='contained' onClick={sendSMS}>{t('send_code', { ns: 'session_video' })}</Button>
                                        {waitingSMS && <CircularProgress size={24} className={styles.buttonProgress} />}
                                    </div>
                                    {smsError && <Typography variant="subtitle2" color="error">{t('error_sending_SMS', { ns: 'session_video' })}</Typography>}
                                </>
                                :
                                <>
                                    <Typography variant="h6">{t('enter_VT_auth_code', { ns: 'session_video' })}</Typography>
                                    <div className={styles.margin}>
                                        <ReactCodeInput ref={codeInputRef} type='number' fields={6} onChange={(code) => setAuthCode(code)} />
                                    </div>
                                    <div className={styles.buttonRow}>
                                        <div className={styles.buttonBox}>
                                            <Button className={styles.margin} disabled={waitingVerify} color='primary' variant='contained' onClick={verifySMS}>{t('submit_button', { ns: 'buttons' })}</Button>
                                            {waitingVerify && <CircularProgress size={24} className={styles.buttonProgress} />}
                                        </div>
                                        <div className={styles.buttonBox}>
                                            <Button className={styles.margin} disabled={waitingSMS} color='default' variant='contained' onClick={() => {
                                                setAuthCode(null)
                                                clearAuthInput()
                                                sendSMS()
                                            }}>{t('resend_code', { ns: 'buttons' })}</Button>
                                            {waitingSMS && <CircularProgress size={24} className={styles.buttonProgress} />}
                                        </div>
                                    </div>
                                    {(verified2FA && verified2FA.isVerified === false) && <Typography variant="subtitle2" color="error">{t('invalid_code', { ns: 'session_video' })}</Typography>}
                                    {error2FA && <Typography variant="subtitle2" color="error">{t('error_validating_code', { ns: 'session_video' })}</Typography>}
                                </>}
                        </>
                        :
                        <>
                            {(showPreSurvey || (rtcDial && (!dialLinkSent || showDialConnection))) ?
                                <>
                                    {showPreSurvey ?
                                        <>
                                            <Typography variant="h4">{surveyRequestId ? t("sorry_text", { ns: 'pre_survey' }) : t("welcome_text", { ns: 'pre_survey' })}</Typography>
                                            <Typography variant="h6">{surveyRequestId ? t("issue_processing_survey", { ns: 'pre_survey' }) : t("click_to_complete_short_survey", { ns: 'pre_survey' })}</Typography>
                                            <Button className={styles.margin} color='primary' variant='contained' onClick={goToSurvey}>{t("GO_TO_SURVEY_button", { ns: 'buttons' })}</Button>
                                        </>
                                        :
                                        <>
                                            {(!dialLinkSent) ?
                                                <>
                                                    <Typography variant="h6">{t('click_to_send_VT_dial_link', { ns: 'dial_link' })}</Typography>
                                                    <div className={styles.buttonBox}>
                                                        <Button className={styles.margin} disabled={waitingDial} color='primary' variant='contained' onClick={sendDial}>{t('send_link', { ns: 'dial_link' })}</Button>
                                                        {waitingDial && <CircularProgress size={24} className={styles.buttonProgress} />}
                                                    </div>
                                                    {dialLinkError && <Typography variant="subtitle2" color="error">{t('error_sending_dial_link', { ns: 'dial_link' })}</Typography>}
                                                </>
                                                :
                                                <>
                                                    <Typography variant="h6">{t('open_link_on_mobile_text', { ns: 'dial_link' })}</Typography>
                                                    <div className={styles.margin}>
                                                        {dialConnected ? <CheckCircleOutlineIcon style={{ fontSize: '5rem' }} /> : <CircularProgress size={'5rem'} />}
                                                        <Typography variant="subtitle1">{dialConnected ? t('dial_connected_text', { ns: 'dial_link' }) : t('dial_connecting_text', { ns: 'dial_link' })}</Typography>
                                                    </div>
                                                    <div className={styles.buttonBox}>
                                                        <Button className={styles.margin} disabled={waitingDial} color='default' variant='contained' onClick={() => sendDial()}>{t('resend_link_text', { ns: 'dial_link' })}</Button>
                                                        {waitingSMS && <CircularProgress size={24} className={styles.buttonProgress} />}
                                                    </div>
                                                </>}
                                        </>}
                                </>
                                :
                                <>
                                    {ready ?
                                        focusGroup ? <>
                                            {/* <Typography variant="h6"></Typography> */}
                                            <Typography variant="subtitle1">{t('keepBrowserWindowOpen', { ns: 'session_video'})}</Typography>
                                        </> : <>
                                            <CountdownDisplay time={scheduledStartTime} offset={countdownOffset} waitingMessage={t('countdown_to_show', { ns: 'session_video' })} completeMessage={t('moments_to_show', { ns: 'session_video' })} betweenMessage={t('next_content_will_load_shortly', { ns: 'session_video' })} isBetween={isBetween} noTimer={techCheck || !showCountdown} />
                                            {showQuiz && <TriviaQuestions sessionId={sessionId} techCheck={techCheck} />}
                                        </>
                                        :
                                        <>
                                            <Typography variant="h6">{sessionStarted ? isResuming ? t('ready_to_resume', { ns: 'session_video' }) : techCheck ? t('ready_to_start', { ns: 'session_video' }) : t('ready_to_join', { ns: 'session_video' }) : t('ready_to_begin', { ns: 'session_video' })}</Typography>
                                            <Typography variant="subtitle1">{t('copyright_note')}</Typography>
                                            <Button className={styles.margin} color='primary' variant='contained' onClick={handleReady}>{sessionStarted ? isResuming ? t('CLICK_TO_RESUME', { ns: 'buttons' }) : techCheck ? t('CLICK_TO_START', { ns: 'buttons' }) : t('CLICK_TO_JOIN', { ns: 'buttons' }) : t('CLICK_TO_PROCEED_button', { ns: 'buttons' })}</Button>
                                        </>}
                                </>}
                        </>}
                </div>
            </>}
        </BrandedPage>
    )
    // }
})

export default PreContentPresenter;
