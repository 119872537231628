import React, { useState, useRef, useEffect } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as workerTimers from 'worker-timers'
import moment from 'moment'

const useStyles = makeStyles({
    margin: {
        margin: '1rem'
    },
})

const timeStringFromMS = (time) => {
    const hours = time / (3600000)
    const remainder = time % (3600000)
    return `${Math.floor(hours)}`.padStart(2, '0') + ":" + `${Math.floor(remainder / 60000)}`.padStart(2, '0') + ":" + `${Math.floor(remainder % 60000 / 1000)}`.padStart(2, '0')
}

const CountdownDisplay = React.memo(({ time, offset, waitingMessage, completeMessage, isBetween, betweenMessage, noTimer }) => {
    const styles = useStyles();
    const [complete, setComplete] = useState(!!noTimer)
    const [countdown, setCountdown] = useState()

    const timeRef = useRef()
    // const offsetRef = useRef()
    const timerRef = useRef()
    const noTimerRef = useRef()

    timeRef.current = moment(time).add(offset, 'ms')
    // offsetRef.current = offset
    noTimerRef.current = noTimer

    useEffect(() => {
        timeRef.current = moment(time).add(offset, 'ms')
    }, [time, offset])

    useEffect(() => {
        const timeDiff = timeRef.current.diff(moment()) //+ offsetRef.current
        if (timeDiff < 0) {
            setComplete(true)
        } else {
            setCountdown(timeDiff)
        }

        return () => {
            console.log('unmounting countdown...')
            if (timerRef.current) workerTimers.clearTimeout(timerRef.current)
        }
    }, [])

    useEffect(() => {
        if ((countdown !== null && countdown !== undefined) && !noTimerRef.current) {
            const timeDiff = timeRef.current.diff(moment()) //+ offsetRef.current
            if (timeDiff < 0) {
                workerTimers.clearTimeout(timerRef.current)
                timerRef.current = null
                setComplete(true)
            } else {
                const tts = timeDiff % 3600000 % 60000 % 1000
                timerRef.current = workerTimers.setTimeout(() => {
                    setCountdown(timeDiff)
                }, tts)
            }

        }
    }, [countdown])

    if (countdown || complete) {
        return (
            complete ? <>
                <Typography variant="h6">{isBetween ? betweenMessage : completeMessage}</Typography>
                {/* <CircularProgress className={styles.margin} /> */}
            </>
                : <>
                    <Typography variant="h6">{waitingMessage}</Typography>
                    <Typography variant="h2">
                        {timeStringFromMS(countdown)}
                        {/* {`${Math.floor(countdown/(3600000))}`.padStart(2, '0')}:{`${Math.floor(countdown % 3600000 / 60000)}`.padStart(2, '0')}:{`${Math.floor(countdown % 3600000 % 60000 / 1000)}`.padStart(2, '0')} */}
                    </Typography>
                </>
        )
    }
})

export default CountdownDisplay;
