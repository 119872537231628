import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    questionContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // height: '100%',
        position: 'relative',
    },
    questionBox: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        // flexWrap: 'wrap',
        // height: '100%',
        width: '100%',
    },
    questionMessage: {
        position: 'relative',
        margin: '0.5rem 0 0.5rem 0',
        width: '70%',
        // padding: '2rem',
        // maxWidth: '500px',
        textAlign: 'center'
    },
    answerButton: {
        // borderRadius: '6px',
        // // width: '100%',
        // verticalAlign: 'middle',
        // // margin: '3px 0 3px 0',
        // boxSizing: 'border-box',
        // border: `3px solid transparent`,
        // backgroundColor: theme.palette.primary.main,
        // color: theme.palette.primary.contrastText,
        // boxShadow: `1px 1px 3px rgba(0,0,0,0.5)`,
        // backgroundColor: '#fff',
        boxShadow: `0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`,
        margin: '12px 24px 12px 24px',
        pointerEvents: ({ disabled }) => disabled ? "none" : "auto"
    },
    whiteButton: {
        backgroundColor: '#fff',
        // boxShadow: `0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`,
    },
    selectedAnswer: {
        // border: `3px solid ${theme.palette.primary.dark}`,
        // color: theme.palette.primary.contrastText,
        // backgroundColor: theme.palette.primary.light,
        // backgroundColor: '#eee',
        boxShadow: `inset 0px 3px 1px -2px rgb(0 0 0 / 20%), inset 0px 2px 2px 0px rgb(0 0 0 / 14%), inset 0px 1px 5px 0px rgb(0 0 0 / 12%)`,
        // filter: `contrast(2)`
    }
}))

const YesNoQuestion = React.memo(({ question, yesLabel, noLabel, onAnswer, submitted, allowChange, whiteButton }) => {
    const styles = useStyles({ disabled: (!!submitted && !allowChange) });
    const [answer, setAnswer] = useState()
    const {t} = useTranslation('buttons');

    useEffect(() => {
        if (typeof answer === 'boolean') onAnswer(answer)
    }, [answer, onAnswer])

    const handleYes = useCallback(() => {
        setAnswer(true)
    }, [])

    const handleNo = useCallback(() => {
        setAnswer(false)
    }, [])

    return (
        <div className={styles.questionContainer}>
            <Typography variant="h5" className={styles.questionMessage}>{question}</Typography>
            <div className={styles.questionBox}>
                <Button color={"primary"} disabled={false} className={clsx(styles.answerButton, {
                    [styles.whiteButton]: !!whiteButton,
                    [styles.selectedAnswer]: answer === true
                })} variant={(answer === true && !whiteButton) ? "contained" : "outlined"} onClick={handleYes}>{yesLabel || t('YES_button')}</Button>
                <Button color={"primary"} disabled={false} className={clsx(styles.answerButton, {
                    [styles.whiteButton]: !!whiteButton,
                    [styles.selectedAnswer]: answer === false
                })} variant={(answer === false && !whiteButton) ? "contained" : "outlined"} onClick={handleNo}>{noLabel || t('NO_button')}</Button>
            </div>
        </div>
    )
})

export default YesNoQuestion;