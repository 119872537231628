import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { IconButton, Button, CircularProgress, Typography, Dialog, DialogTitle, DialogContent, DialogActions, MobileStepper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    content: {
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
    },
    img: {
        maxHeight: '65vh',
        maxWidth: '65vw',
        width: '100%',
        height: '100%'
    },
    actions: {
        display: 'block'
    }
})

const HelpModal = React.memo(({ slides }) => {
    const styles = useStyles();

    const [open, setOpen] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    const {t} = useTranslation('buttons');

    useEffect(() => {
        if (!open) setActiveStep(0)
    }, [open])

    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleNext = useCallback(() => {
        setActiveStep(prev => prev + 1)
    }, [])

    const handleBack = useCallback(() => {
        setActiveStep(prev => prev - 1)
    }, [])

    return (
        <>
            <IconButton size="small" onClick={handleOpen}>
                <HelpOutlineIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{t('help_text')}</DialogTitle>
                <DialogContent className={styles.content}>
                    {/* <div> */}
                        {slides[activeStep].img && <img
                            className={styles.img}
                            src={slides[activeStep].img}
                        />}
                        <Typography>{slides[activeStep].text}</Typography>
                        {/* <MobileStepper
                        steps={slides.length}
                        position="static"
                        variant="text"
                        activeStep={activeStep}
                        nextButton={
                            <Button size="small" onClick={activeStep === slides.length - 1 ? handleClose : handleNext}>
                                {activeStep === slides.length - 1 ? <>
                                    OK
                                </> : <>
                                    Next
                                    <KeyboardArrowRight />
                                </>}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                <KeyboardArrowLeft />
                                Back
                            </Button>
                        }
                    /> */}
                    {/* </div> */}
                </DialogContent>
                <DialogActions className={styles.actions}>
                    {/* <Button onClick={handleClose} color="primary" disabled={true}>
                        OK
                    </Button> */}
                    <MobileStepper
                        steps={slides.length}
                        position="static"
                        variant="text"
                        activeStep={activeStep}
                        nextButton={
                            <Button size="small" onClick={activeStep === slides.length - 1 ? handleClose : handleNext}>
                                {activeStep === slides.length - 1 ? <>
                                    {t('OK_button')}
                                </> : <>
                                    {t('NEXT_button')}
                                    <KeyboardArrowRight />
                                </>}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                <KeyboardArrowLeft />
                                {t('back_button')}
                            </Button>
                        }
                    />
                </DialogActions>
            </Dialog>
        </>
    )

})

export default HelpModal;