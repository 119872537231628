import React from 'react'
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
// import TvIcon from '@material-ui/icons/TvTwoTone';
import MovieIcon from '@material-ui/icons/Movie';
// import LiveTvIcon from '@material-ui/icons/LiveTvTwoTone';
// import MusicVideoIcon from '@material-ui/icons/MusicVideoTwoTone';
// import SlideshowIcon from '@material-ui/icons/SlideshowTwoTone';
import { RiMovie2Fill, RiMickeyFill, RiGameFill } from 'react-icons/ri'
import { FaFootballBall } from 'react-icons/fa'
import { IoMdFootball } from 'react-icons/io'
import { GiBasketballBall } from 'react-icons/gi'
// import { ImVideoCamera } from 'react-icons/im'
// import { TiVideo } from 'react-icons/ti'
import { SiNetflix, SiHulu, SiWarnerbrosDot, SiDcentertainment, SiAmazon, SiXbox, SiPlaystation, SiEpicgames, SiRiotgames, SiNintendo, SiNintendonetwork, SiNintendoswitch } from 'react-icons/si'

export const GameControllerThumb = (props) => (
    <span {...props}>
        <SportsEsportsIcon />
    </span>
);

export const MovieThumb = (props) => (
    <span {...props}>
        <MovieIcon />
    </span>
);

export const NintendoSwitchThumb = (props) => (
    <span {...props}>
        <SiNintendoswitch style={{transform: 'scale(1.75)'}}/>
    </span>
);

export const NintendoNetworkThumb = (props) => (
    <span {...props}>
        <SiNintendonetwork style={{transform: 'scale(1.75)'}}/>
    </span>
);

export const NintendoLogoThumb = (props) => (
    <span {...props}>
        <SiNintendo style={{transform: 'scale(1.75)'}}/>
    </span>
);

export const RiotGamesThumb = (props) => (
    <span {...props}>
        <SiRiotgames style={{transform: 'scale(1.75)'}}/>
    </span>
);

export const EpicGamesThumb = (props) => (
    <span {...props}>
        <SiEpicgames style={{transform: 'scale(1.75)'}}/>
    </span>
);

export const PlaystationThumb = (props) => (
    <span {...props}>
        <SiPlaystation style={{transform: 'scale(1.75)'}}/>
    </span>
);

export const XboxThumb = (props) => (
    <span {...props}>
        <SiXbox style={{transform: 'scale(1.75)'}}/>
    </span>
);

export const AmazonThumb = (props) => (
    <span {...props}>
        <SiAmazon style={{transform: 'scale(1.75)'}}/>
    </span>
);

export const DCThumb = (props) => (
    <span {...props}>
        <SiDcentertainment style={{background: 'white', borderRadius: '50%', padding:'1px', transform: 'scale(1.75)'}}/>
    </span>
);

export const WBThumb = (props) => (
    <span {...props}>
        <SiWarnerbrosDot style={{background: 'white', borderRadius: '50%', padding:'1px', transform: 'scale(1.75)'}}/>
    </span>
);

export const HuluThumb = (props) => (
    <span {...props}>
        <SiHulu style={{transform: 'scale(1.75)'}}/>
    </span>
);

export const NetflixThumb = (props) => (
    <span {...props}>
        <SiNetflix style={{transform: 'scale(1.75)'}}/>
    </span>
);

export const BasketballThumb = (props) => (
    <span {...props}>
        <GiBasketballBall style={{transform: 'scale(1.75)'}}/>
    </span>
);

export const SoccerBallThumb = (props) => ( //new icon?
    <span {...props}>
        <IoMdFootball />
    </span>
);

export const FootballThumb = (props) => (
    <span {...props}>
        <FaFootballBall />
    </span>
);

export const PacmanThumb = (props) => (
    <span {...props}>
        <RiGameFill style={{transform: 'scale(1.75)'}} />
    </span>
);

export const MovieReelThumb = (props) => (
    <span {...props}>
        <RiMovie2Fill style={{transform: 'scale(1.75)'}} />
    </span>
);

export const MouseEarsThumb = (props) => (
    <span {...props}>
        <RiMickeyFill style={{transform: 'scale(1.75)'}} />
    </span>
);