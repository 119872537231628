import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import seasiLogo from './resources/seasi-logo.jpg'
import seasiLogo from './resources/seasi-logo.png'
import pfmLogo from './resources/pfm-logo.png'
import vvtvLogo from './resources/vv-logo.png'
import ewLogo from './resources/ew-logo.png'
import background from './resources/black-bg.jpg'
import moment from 'moment'

const useStyles = makeStyles({
    fullscreen: {
        width: '100vw',
        height: '100vh',
        overflow: 'hidden'
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        // background: '#222'
        background: `url(${background})`,
        backgroundSize: 'cover'
    },
    headerLogo: {
        height: '100%',
        maxHeight: '80px'
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        width: '55%',
        minWidth: '300px',
        maxWidth: '800px'
    },
    fixedHeader: {
        position: 'static',
        top: 0,
        width: '100%',
        height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    fixedFooter: {
        position: 'static',
        bottom: 0,
        width: '100%',
        // height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    white: {
        color: 'white'
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        height: '100%',
        width: '95%',
        // minWidth: '300px',
        // maxWidth: '800px',
        color: 'white',
        position: 'relative'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        // marginTop: 'auto',
        minHeight: '68%',
        height: 'auto',
        marginBottom: 'auto'
        // width: '55%',
        // minWidth: '400px',
        // maxWidth: '800px',
        // minHeight: '300px'
    },
})

const logos = {
    'SEA': seasiLogo,
    'PFM': pfmLogo,
    'VTV': vvtvLogo,
    'EWX': ewLogo,
    'CPT': seasiLogo,
}

const ErrorPage = React.memo(({ error, message, logo }) => {

    const styles = useStyles();

    return (
        <div className={styles.fullscreen}>
            <div className={styles.root}>
                <div className={styles.fixedHeader}>
                    <img alt="logo" src={logos[logo]} className={styles.headerLogo} />
                </div>
                {/* <Card elevation={12} className={styles.card}>
                <img src={logos[logo]} className={styles.headerLogo} />
                <CardHeader
                        title={error}
                        titleTypographyProps={{ variant: 'h3' }}
                        subheader={message}
                        subheaderTypographyProps={{ variant: 'h5' }}
                    />
            </Card> */}
                <div className={styles.content}>
                    <div className={styles.titleBox}>
                        <Typography variant="h3">{error}</Typography>
                        <br />
                        <Typography variant="h5">{message}</Typography>
                    </div>
                </div>
                <div className={styles.fixedFooter}>
                    <Typography className={styles.white} variant="caption">&copy; {moment().format('YYYY')} Screen Engine/ASI</Typography>
                </div>
            </div>
        </div>
    )
})

export default ErrorPage