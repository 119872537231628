import React, { useContext, useState, useRef, useEffect, useMemo, useCallback } from 'react';
import queryString from 'query-string';
import useWebSocket from 'react-use-websocket';
import { initDB } from 'react-indexed-db';
import * as workerTimers from 'worker-timers'
import VideoPage from './VideoPage'
import LoadingPage from './LoadingPage'
import { DBConfig } from './DBConfig';
import './App.css';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { unstable_createMuiStrictModeTheme as createMuiTheme, responsiveFontSizes, ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button } from '@material-ui/core';
import UAParser from 'ua-parser-js'
import config from './config.json'
import ThemeContext from './ThemeContext';
import { clearLocalStorage } from './hooks';
import SystemChecker from './SystemChecker';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const short = require('short-uuid');
const sessionContextId = short.generate()

initDB(DBConfig);

const parser = new UAParser();

const isMobileDevice = () => {
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

// const isBrowser = (u) => {
//   for (let i = 0; i < navigator.plugins.length; i++) {
//     if (navigator.plugins[i].name != null && navigator.plugins[i].name.substr(0, u.length) === u) {
//       return true;
//     }
//   }
//   return false;
// }

const isBrowser = (u) => {
  if (navigator.userAgentData) {
    for (let brand_version_pair of navigator.userAgentData.brands) {
      if (brand_version_pair.brand.substr(0, u.length) === u) {
        return true;
      }
    }
    return false;
  }
  return false;
}

const browserCheck = async (uaName) => {
  const isBrave = (navigator.brave && await navigator.brave.isBrave() || false)
  const browsers = {
    Brave: isBrave,
    Firefox: !!window.InstallTrigger,
    Safari: !!window.ApplePaySession || /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || window.safari.pushNotification),
    Opera: !!window.opr || !!window.opera || isBrowser("Opera"),
    Edge: isBrowser("GREASE") || isBrowser("Microsoft Edg"),
    Chrome: isBrowser("Google Chrome"),
    Chromium: isBrowser("Chromium"),
    // Chrome: !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime),
  }
  const detectedBrowser = Object.keys(browsers).find(k => !!browsers[k])
  return detectedBrowser || uaName
}

const uploadGuestPhotoPromise = (file, sessionId, guestId, token) =>
  fetch(`https://${config.rest.sessionGuestAPI}/photo`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify({
      filename: file.name,
      filetype: file.type,
      sessionId,
      guestId
    })

  }).then(response => {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    } else {
      return response.json().then(res => {
        throw res
      })
    }
  }).then(signedResponseData => {
    const formData = new FormData();
    Object.entries(signedResponseData.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("file", file);
    return fetch(signedResponseData.url, {
      method: 'POST',
      mode: 'cors',
      body: formData

    })
  }).then(response => {
    if (response.status >= 200 && response.status <= 299) {
      return response
    } else {
      throw response
    }
  })

const useStyles = makeStyles({
  fullscreen: {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    background: 'black',
    position: 'relative',
    zIndex: 1
  },
})

const App = React.memo(({ location: { search, state }, match: { params: { sessionId, requestId } }, history }) => {
  // console.log('APP RENDER!!!')
  const styles = useStyles();
  const { setTeam } = useContext(ThemeContext);
  // const [connectionError, setConnectionError] = useState()
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState();
  const [showVideo, setShowVideo] = useState(false)
  const [videoCheckStatus, setVideoCheckStatus] = useState({})
  const [sessionStarted, setSessionStarted] = useState(false);
  const [videoIndex, setVideoIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startTime, setStartTime] = useState();
  const [stopTime, setStopTime] = useState();
  const [resetDial, setResetDial] = useState(false);
  const [advanceIndex, setAdvanceIndex] = useState(0);
  const [sessionEnded, setSessionEnded] = useState(false);
  // const [surveyError, setSurveyError] = useState(false);
  const [surveyRequestId, setSurveyRequestId] = useState();
  const [preShowSurvey, setPreShowSurvey] = useState(false)
  const [hasChat, setHasChat] = useState(false)
  const [openMessage, setOpenMessage] = useState(false)
  const [guestMessage, setGuestMessage] = useState('')
  const [smsSent, setSmsSent] = useState(false)
  const [resendSMS, setResendSMS] = useState()
  const [smsError, setSmsError] = useState()
  const [verified2FA, setVerified2FA] = useState()
  const [error2FA, setError2FA] = useState()
  const [dialLinkSent, setDialLinkSent] = useState(false)
  const [dialLinkError, setDialLinkError] = useState()
  const [guestEjected, setGuestEjected] = useState()
  const [fadeOut, setFadeOut] = useState(false)
  const [techCheckComplete, setTechCheckComplete] = useState(false)
  const [techCheckApproved, setTechCheckApproved] = useState(false)
  const [techCheckCanceled, setTechCheckCanceled] = useState(false)
  // const [online, setOnline] = useState(true)

  // const { getAll, clear } = useIndexedDB('scores');
  const fullScreenMode = useFullScreenHandle();

  const smsSentRef = useRef()
  const teamRef = useRef()
  const placementRef = useRef()
  // const dialMessage = useRef()
  const requestIdRef = useRef()
  requestIdRef.current = requestId
  const loadingRef = useRef()
  loadingRef.current = loading
  const pingRef = useRef()
  const videoDataRef = useRef()
  // const videoStartTimeRef = useRef()
  // const connectionOffsetRef = useRef(0)

  const { id: token, state: surveyToken, cid, pid: encryptedProjectId, openchat: showChat } = queryString.parse(search);
  const id = token || state?.id

  const socketUrl = `wss://${config.ws.videoSessionWS}?access_token=${id}&sessionId=${sessionId}&scx=${sessionContextId}`;
  const getSocketUrl = useCallback(() => {
    let wsUrl = socketUrl + `&cxtime=${Date.now()}`
    if (cid) wsUrl += `&cid=${cid}`
    return wsUrl
  }, [socketUrl, cid])
  const connectedRef = useRef(false);
  const [connected, setConnected] = useState(false);
  const { t } = useTranslation('translation');
  const WS_OPTIONS = useMemo(() => ({
    // const WS_OPTIONS = {
    onOpen: () => {
      // connected.current = true;
      setConnected(true)
      // const message = {
      //   action: 'session',
      //   sessionId: sessionId,
      //   clientTime: Date.now()
      // }
      // sendMessage(JSON.stringify(message));
      // console.log('setting worker timer')
      // pingRef.current = workerTimers.setInterval(() => {
      //   console.log('keeping connection alive...')
      //   if (sendMessage) sendMessage(JSON.stringify({
      //     action: 'keepAlive'
      //   }))
      // }, 300000)

      // getAll().then((storedScores) => {
      //   if (storedScores.length) {
      //     sendMessage(JSON.stringify(storedScores))
      //     return clear().then(() => {
      //       console.log('stored scores cleared!')
      //     })
      //   } else {
      //     return Promise.resolve()
      //   }
      // }).then(() => {
      //   console.log('finished processing indexeddb')
      // }).catch((error) => {
      //   console.log(error)
      // })
    },
    // onError: (e) => setConnectionError(e),
    onError: (e) => history.push('/error', { error: t('error_text'), message: t('unable_auth_message') }),
    shouldReconnect: (closeEvent) => {
      console.log(closeEvent)
      // connected.current = false;
      setConnected(false)
      if (pingRef.current) {
        console.log('removing keep alive...')
        workerTimers.clearInterval(pingRef.current)
        pingRef.current = null
      }
      return true
      // return closeEvent.code !== 1006
    },
    // }
  }), [history, t]);
  // const [messageHistory, setMessageHistory] = useState([]);
  const { sendMessage, lastMessage, readyState } = useWebSocket(getSocketUrl, WS_OPTIONS);

  useEffect(() => {
    console.log('WS READY STATE: ', readyState)
  }, [readyState])

  useEffect(() => {
    if (connected) {
      const message = {
        action: 'session',
        sessionId: sessionId,
        reconnect: connectedRef.current,
        viewerTime: Date.now()
      }
      sendMessage(JSON.stringify(message));
      connectedRef.current = true
      console.log('setting worker timer')
      pingRef.current = workerTimers.setInterval(() => {
        console.log('keeping connection alive...')
        const now = Date.now()
        const videoTime = videoDataRef?.current?.getVideoPosition()
        if (sendMessage) sendMessage(JSON.stringify({
          action: 'keepAlive',
          videoTime,
          viewerTime: now,
        }))
      }, 57000)
    }
  }, [connected, sendMessage, sessionId])

  useEffect(() => {
    return () => {
      console.log('removing keep alive...')
      if (pingRef.current) {
        workerTimers.clearInterval(pingRef.current)
        pingRef.current = null
      }
    }
  }, [])

  useEffect(() => {
    smsSentRef.current = smsSent
  }, [smsSent])

  useEffect(() => {
    // const messageTime = Date.now();
    if (lastMessage !== null) {
      const message = JSON.parse(lastMessage.data)
      console.log(message)
      if (message.messageType === 'sessionData') {
        // if (!!message.userState.status || message.userState.status === 'Waiting') {
        //   sendMessage(JSON.stringify({
        //     action: 'status',
        //     userStatus: 'Not Ready',
        //   }));
        // }
        // setClientOffset(message.connectionOffset)
        // setBranding(message.dialBranding)
        // setDialStyle(message.dialStyle)

        // connectionOffsetRef.current = message.connectionOffset
        // videoStartTimeRef.current = message.sessionStartTime
        setTeam(message.team)
        teamRef.current = message.team
        if (message.role !== 'Client' && i18next.language !== message.viewerLanguage) { // change language if needed
          i18next.changeLanguage(message.viewerLanguage);
        }
        placementRef.current = message.surveyPlacement
        setSession(message)
        setVerified2FA({
          isVerified: message.twoFactorComplete || null
        })
        if (!message.techCheck && (message.hasSurvey && message.surveyPlacement !== 'post') && message.role === 'Viewer') {
          if (!message.surveyToken || (!!surveyToken && message.surveyToken !== surveyToken)) {
            if (message.requestId === requestIdRef.current && !!surveyToken) {
              sendMessage(JSON.stringify({
                action: 'survey',
                surveyToken,
                requestId
              }));
            } else if (['pre', 'both', 'all'].includes(message.surveyPlacement)) {
              if (message.requestId) {
                setSurveyRequestId(requestIdRef.current)
                // setSurveyError(true)
              }
              setPreShowSurvey(true)
            }
          }
        }
        if (message.complete) {
          setSessionEnded(true)
        } else if (message.started) {
          // if (!onPageRef.current) setOnPage(true)
          const sessionStartTime = message.contentStartTime && message.contentStartTime[message.videoIndex || 0]
          const startIndex = ((message.currentIndex || 0) >= (message.videoIndex || 0)) && ((message.currentIndex || 0) < (message.contentLength?.length)) ? (message.currentIndex || 0) : message.videoIndex || 0
          setStartTime(message.liveStartTime || (sessionStartTime && sessionStartTime + 'Z'))
          setVideoIndex(message.videoIndex || 0)
          setCurrentIndex(startIndex)
          setSessionStarted(true);
          // setSendData(true);
        } //else if (!onPageRef.current) {
        //   setOnPage(true)
        // }
      } else if (message.messageType === 'startSession') {
        // videoStartTimeRef.current = message.serverTime
        setStartTime(message.sessionStartTime)
        setVideoIndex(message.videoIndex)
        setSessionStarted(true);
        // dialMessage.current && dialMessage.current(JSON.stringify({
        //   action: 'startVideo'
        // }))
      } else if (message.messageType === 'liveStart') {
        // videoStartTimeRef.current = message.serverTime
        setStartTime(message.liveStartTime)
        setResetDial(true);
        // setSessionStarted(true);
        // dialMessage.current && dialMessage.current(JSON.stringify({
        //   action: 'startVideo'
        // }))
      } else if (message.messageType === 'stopSession') {
        if (message.videoEndTime) {
          setStopTime(message.videoEndTime)
        } else {
          setSessionEnded(true)
        }
        // dialMessage.current && dialMessage.current(JSON.stringify({
        //   action: 'stopVideo'
        // }))
      } else if (message.messageType === 'ejectGuest') {
        setGuestEjected({ reason: message.ejectReason, team: teamRef.current || (message.sessionTeam || 'PFM') })
        if (loadingRef.current) {
          history.push('/eject', { reason: message.ejectReason, team: teamRef.current || (message.sessionTeam || 'PFM') })
        }
        // dialMessage.current && dialMessage.current(JSON.stringify({
        //   action: 'ejectGuest'
        // }))
        // history.push('/eject', { reason: message.ejectReason, team: teamRef.current || (message.sessionTeam || 'SEA') })
      } else if (message.messageType === 'dropped') {
        history.push('/error', { logo: teamRef.current || (message.sessionTeam || 'PFM'), error: t('disconnectedFromZoom'), message: t('sorryMustBeConnected') })
      } else if (message.messageType === 'blocked') {
        history.push('/error', { logo: teamRef.current || (message.sessionTeam || 'PFM'), error: t('unauthorized_text'), message: t('sorryYouAreUnauthorized') })
      } else if (message.messageType === 'reset') {
        history.push('/error', { logo: teamRef.current || (message.sessionTeam || 'PFM'), error: t('disconnected_text'), message: t(('sorryYouAreDisconnected')) })
      } else if (message.messageType === 'chatMessage') {
        setHasChat(true)
      } else if (message.messageType === 'guestMessage') {
        setOpenMessage(true)
        setGuestMessage(message.messageText);
      } else if (message.messageType === 'guestCompleted') {
        history.push('/complete', { sessionid: message.sessionId, id: message.userId, requestid: message.requestId, redirect: true, team: teamRef.current || message.team, scheduledStartTime: message.scheduledStartTime, videoTitle: message.videoTitle, techCheck: message.techCheck, returning: true })
      } else if (message.messageType === 'goToSurvey') {
        let surveyUrl;
        switch (message.surveyType) {
          case 'Decipher':
            surveyUrl = `https://survey.everyonesez.com/survey/${message.surveyId}?list=1&sessionid=${message.sessionId}&id=${message.userId}&postrequestid=${message.requestId}`
            break;
          case 'Qualtrics':
          default:
            surveyUrl = `https://screenengine.qualtrics.com/jfe/form/${message.surveyId}?sessionid=${message.sessionId}&id=${message.userId}&postrequestid=${message.requestId}`
            break;
        }
        if (['inter', 'post'].includes(placementRef.current)) {
          surveyUrl += `&encryptedsessionid=${sessionId}&jwt=${id}`
        }
        if (message.surveyToken) surveyUrl += `&state=${message.surveyToken}`
        window.location = surveyUrl
      } else if (message.messageType === 'moveForward') {
        setAdvanceIndex(prev => prev + 1)
        // setSessionEnded(true)
      } else if (message.messageType === 'refresh' || message.messageType === 'resetSession') {
        if (message.messageType === 'resetSession') {
          clearLocalStorage((session.contentLength || []).map((x, i) => [
            `sliderscore${session.sessionId}-${i}-${session.techCheck ? '-TC' : ''}`,
            `tuneinout${session.sessionId}-${i}-${session.techCheck ? '-TC' : ''}`
          ]).flat())
          // clearLocalStorage([
          //   `sliderscore${session.sessionId}${session.techCheck ? '-TC' : ''}`,
          //   `tuneinout${session.sessionId}${session.techCheck ? '-TC' : ''}`
          // ])
        }
        window.location.reload()
      } else if (message.messageType === 'smsSent') {
        smsSentRef.current ? setResendSMS({}) : setSmsSent(true)
      } else if (message.messageType === 'smsError') {
        setSmsError(message.error);
      } else if (message.messageType === 'verify2FA') {
        // if (message.isValid) {
        //   setVerified2FA({
        //     isVerified: true
        //   })
        // } else {
        //   if (message.error) {
        //     setError2FA(message.error)
        //   } else {
        //     setVerified2FA(false)
        //   }
        // }
        if (message.error) {
          setError2FA(message.error)
        } else {
          setVerified2FA({
            isVerified: message.isValid
          })
        }
        // setGuestMessage(message.chatText);
      } else if (message.messageType === 'dialLinkSent') {
        setDialLinkSent(true)
      } else if (message.messageType === 'dialLinkError') {
        setDialLinkError(message.error)
      } else if (message.messageType === 'fadeOut') {
        setFadeOut(true)
      } else if (message.messageType === 'techCheckComplete') {
        setTechCheckComplete(true)
        setTechCheckApproved(!!message.isApproved && !message.isCanceled)
        setTechCheckCanceled(!!message.isCanceled)
      }
    }
    // lastMessageRef.current = lastMessage
  }, [lastMessage, history, requestId, sendMessage, setTeam, surveyToken, sessionId, id]);

  useEffect(() => {
    if (session) {
      if (!(session.techCheck && session.selfGuided)) {
        parser.setUA(session.userBrowser);
        const broswerInfo = parser.getResult()
        browserCheck(broswerInfo.browser.name).then((browser) => {
          console.log('GOT BROWSER', browser)
          if (!(session.allowedBrowsers || []).includes(browser)) {
            history.push('/error', { logo: session.team, error: t('unsupported_browser_text'), message: t('browserIsntSupported') + (session.allowedBrowsers || []).join(', ') })
          } else if (!session.allowMobile && isMobileDevice()) {
            history.push('/error', { logo: session.team, error: t('unsupported_device_text'), message: t('devicesNotSupported') })
          } else {
            // if (session.hasSurvey && session.surveyPlacement !== 'post') {
            //   if (!session.surveyToken) {
            //     if (session.requestId === requestId && !!state) {
            //       sendMessage(JSON.stringify({
            //         action: 'survey',
            //         surveyToken: state,
            //         requestId
            //       }));
            //     } else {
            //       if (session.requestId) setSurveyError(true)
            //       setPreShowSurvey(true)
            //     }
            //   }
            // }
            setLoading(false)
          }
        })
      } else {
        setLoading(false)
      }
    }
  }, [session, history])

  const closeMessage = useCallback(() => {
    setOpenMessage(false)
    setGuestMessage('')
  }, [])

  const toggleFullScreen = useCallback((event) => {
    fullScreenMode.active ? fullScreenMode.exit() : fullScreenMode.enter()
  }, [fullScreenMode]);

  const handleShowVideo = useCallback(() => {
    setShowVideo(true)
  }, [])

  const handleVideoError = useCallback(() => {
    // unloadRef.current && window.removeEventListener('beforeunload', handleUnload)
    if ((session.techCheck && session.selfGuided)) {
      const error = new Error('Playback Error')
      setShowVideo(false)
      setVideoCheckStatus({
        failed: true,
        error
      })
    } else {
      window.location.reload()
    }
  }, [session])

  const handleVideoComplete = useCallback((index) => {
    const requestId = short.generate()
    const oldIndex = videoIndex || 0
    const nextIndex = oldIndex === index ? index + 1 : oldIndex + 1
    const hasNext = (session.contentLength || []).length > (nextIndex || 0)
    const hasSurvey = session.hasSurvey && (hasNext ? ['inter', 'all'].includes(session.surveyPlacement) : session.surveyPlacement !== 'pre')
    if (!session.techCheck && hasSurvey && session.role === 'Viewer') {
      let surveyUrl;
      switch (session.surveyType) {
        case 'Decipher':
          surveyUrl = `https://survey.everyonesez.com/survey/${session.surveyId}?list=1&sessionid=${session.sessionId}&id=${session.userId}&postrequestid=${requestId}`
          break;
        case 'Qualtrics':
        default:
          surveyUrl = `https://screenengine.qualtrics.com/jfe/form/${session.surveyId}?sessionid=${session.sessionId}&id=${session.userId}&postrequestid=${requestId}`
          break;
      }
      if (['inter', 'post'].includes(session.surveyPlacement)) {
        surveyUrl += `&encryptedsessionid=${sessionId}&jwt=${id}`
      }
      if (surveyToken || session.surveyToken) {
        surveyUrl += `&state=${surveyToken || session.surveyToken}`
      }
      sendMessage(JSON.stringify({
        action: 'status',
        userStatus: hasNext ? 'Inter-Survey' : 'Post-Survey',
        userSubstatus: null,
        finished: !hasNext,
        currentIndex: nextIndex,
        requestId
      }));
      window.location = surveyUrl
    } else if (!session.techCheck && hasNext) {
      console.log('NEXT VIDEO')
      sendMessage(JSON.stringify({
        action: 'status',
        userStatus: 'Waiting',
        userSubstatus: null,
        currentIndex: nextIndex
      }));
      setCurrentIndex(nextIndex)
    } else if ((session.techCheck && session.selfGuided)) {
      setShowVideo(false)
      sendMessage(JSON.stringify({
        action: 'status',
        userStatus: 'Comfirming Playback',
        userSubstatus: null
      }));
      setVideoCheckStatus({
        complete: true
      })
    } else {
      sendMessage(JSON.stringify({
        action: 'status',
        userStatus: 'Complete',
        userSubstatus: null,
        finished: true,
        requestId
      }));
      history.push('/complete', { sessionid: session.sessionId, id: session.userId, requestid: requestId, redirect: true, team: session.team, scheduledStartTime: session.scheduledStartTime, videoTitle: session.videoTitle, techCheck: session.techCheck })
    }
  }, [session, surveyToken, history, sendMessage, videoIndex, sessionId, id])

  const handleStatusChange = useCallback(({ current, retry, failed, error, noRetry }) => {
    console.log('OH NO', JSON.stringify({
      action: 'status',
      userStatus: failed ? 'Failed' : current,
      userSubstatus: error?.message || (retry ? 'Retry' : null),
      failed: !!noRetry
    }))
    // sendMessage(JSON.stringify({
    //   action: 'status',
    //   userStatus: failed ? 'Failed' : current,
    //   userSubstatus: error?.message || (retry ? 'Retry' : null),
    //   failed: !!noRetry
    // }));
  }, [sendMessage])

  const handleTechCheckComplete = useCallback((canceled) => {
    sendMessage(JSON.stringify({
      action: 'status',
      userStatus: canceled ? 'Canceled' : 'Complete',
      finished: true,
      canceled
    }));
  }, [sendMessage])

  const handleToScheduling = useCallback(() => {
    history.push(`/register/${session.sessionId}?${session.externalRecruitId ? `qid=${session.externalRecruitId}&` : ''}rid=${session.registrationId}`, { force: true })
  }, [session, history])

  const handlePhotoUpload = useCallback((file) => {
    return uploadGuestPhotoPromise(file, session.sessionId, session.userId, id)
  }, [session, id])

  const geoData = useMemo(() => {
    return session?.geoData
  }, [session])

  const allowedBrowsers = useMemo(() => {
    return session?.allowedBrowsers || []
  }, [session])

  const allowedCountries = useMemo(() => {
    return session?.allowedCountries || []
  }, [session])

  const subGeoRestriction = useMemo(() => {
    return session?.subGeoRestriction || []
  }, [session])

  return (
    <FullScreen handle={fullScreenMode} className={styles.fullscreen}>
      {loading && <LoadingPage />}
      {!loading && <div className={styles.root}>
        {/* {guestMessage && <ChatOverlay message={guestMessage} onAccept={closeChat} />} */}
        <Dialog disablePortal open={openMessage}>
          <DialogTitle>{t('message_from_admin_text')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {guestMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color='primary' variant='contained' onClick={closeMessage}>
              {t('ok_text')}
            </Button>
          </DialogActions>
        </Dialog>
        {(session.techCheck && session.selfGuided) && <SystemChecker
          hidden={showVideo}
          startSessionFlow={handleShowVideo}
          sessionFlowStatus={videoCheckStatus}
          onStatusChange={handleStatusChange}
          onComplete={handleTechCheckComplete}
          uploadPhoto={handlePhotoUpload}
          techCheckComplete={(session.state === 'Approved' || session.state === 'Waitlisted' || session.state === 'Canceled') || techCheckComplete}
          isApproved={(session.state === 'Approved') || techCheckApproved}
          isCanceled={(session.state === 'Canceled') || techCheckCanceled}
          enableGeoRestriction={session.enableGeoRestriction}
          allowedCountries={allowedCountries}
          enhancedGeoRestriction={session.enhancedGeoRestriction}
          subGeoRestrictionType={session.subGeoRestrictionType}
          subGeoRestriction={subGeoRestriction}
          geoData={geoData}
          allowedBrowsers={allowedBrowsers}
          allowMobile={session.allowMobile}
          focusGroup={session.type === 'Focus Group'}
          checkInTime={session.guestCheckInTime}
          toScheduling={handleToScheduling}
        />}
        <VideoPage
          token={id}
          socketConnected={connected}
          disableInteraction={(session.techCheck && session.selfGuided) && !showVideo}
          showChat={showChat}
          {...session}
          encryptedProjectId={encryptedProjectId}
          selfGuided={session.selfGuided && showVideo}
          encryptedSessionId={sessionId}
          fadeOut={fadeOut}
          guestEjected={guestEjected}
          showPreSurvey={preShowSurvey}
          surveyToken={surveyToken || session.surveyToken}
          surveyRequestId={surveyRequestId}
          dialLinkSent={session.dialLinkSent || dialLinkSent}
          dialLinkError={dialLinkError}
          smsSent={smsSent}
          smsError={smsError}
          resend={resendSMS}
          verified2FA={verified2FA}
          error2FA={error2FA}
          hasChat={session.hasChat || hasChat}
          sessionStarted={sessionStarted}
          sessionStartTime={startTime}
          videoIndex={videoIndex}
          currentIndex={currentIndex}
          videoStopTime={stopTime || session.autoStopTime}
          resetDial={resetDial}
          sessionEnded={sessionEnded}
          advanceIndex={advanceIndex}
          sendMessage={sendMessage}
          videoDataRef={videoDataRef}
          toggleFullScreen={toggleFullScreen}
          isFullscreen={!!(fullScreenMode?.active)}
          onVideoError={handleVideoError}
          onVideoComplete={handleVideoComplete}
        />
      </div>}
    </FullScreen>
  )
})

export default App;
