import * as React from 'react';
import clsx from 'clsx';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import WarningIcon from '@material-ui/icons/Warning';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

export const componentStyles = (theme) => ({
    root: {
        display: 'block',
        color: theme.palette.text.disabled,
        fontSize: 'calc(6/5 * 3rem)',//'6rem',
        '&$completed': {
            color: theme.palette.success.main,
        },
        '&$active': {
            color: theme.palette.primary.main,
        },
        '&$error': {
            color: theme.palette.error.main,
        },
    },
    /* Styles applied to the SVG text element. */
    text: {
        fill: theme.palette.primary.contrastText,
        fontSize: theme.typography.caption.fontSize,
        fontFamily: theme.typography.fontFamily,
    },
    active: {},
    completed: {},
    error: {},
});

const useStyles = makeStyles({
    spinnerBox: {
        height: 'calc(6/5 * 3rem)',//'6rem',
        width: 'calc(6/5 * 3rem)',//'6rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    spinner: {
        overflow: 'visible'
    }
})

const LoadingStepIcon = React.forwardRef(function LoadingStepIcon(props, ref) {
    const { completed = false, active = false, error = false, classes } = props;

    const styles = useStyles();
    const className = clsx(classes.root, {
        [classes.active]: active,
        [classes.error]: error,
        [classes.completed]: completed,
    });

    if (error) {
        return <WarningIcon className={className} ref={ref} />;
    }

    if (completed) {
        return <CheckCircleOutlineIcon className={className} ref={ref} />;
    }

    if (active) {
        return (
            <div className={styles.spinnerBox}>
                <CircularProgress size='3rem' thickness={4.32} className={className} ref={ref} classes={{ svg: styles.spinner }} />
            </div>
        );
    }

    return <RadioButtonUncheckedIcon className={className} ref={ref} />;

});

export default withStyles(componentStyles)(LoadingStepIcon);