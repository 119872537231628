import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BrandedPage from './BrandedPage'
// import LoadingPage from './LoadingPage';
import SessionInfoBanner from './SessionInfoBanner';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    fullscreen: {
        width: '100vw',
        height: '100vh',
        overflow: 'hidden'
    },
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        // marginTop: 'auto',
        minHeight: '68%',
        height: 'auto',
        marginBottom: 'auto'
        // width: '55%',
        // minWidth: '400px',
        // maxWidth: '800px',
        // minHeight: '300px'
    }
})

const EventFullPage = React.memo(({ location: { state }, history }) => {
    const styles = useStyles();
    const {t} = useTranslation('self_tech_check_step_6');

    const { sessionTeam, sessionDate, videoTitle } = state

    useEffect(() => {
        if (!sessionTeam || !sessionDate || !videoTitle) {
            history.push('/')
        }
    }, [history, sessionTeam, sessionDate, videoTitle])

    return (
        <div className={styles.fullscreen}>
            <div className={styles.root}>
                <BrandedPage team={sessionTeam}>
                    <SessionInfoBanner videoTitle={videoTitle} sessionDate={sessionDate} />
                    <div className={styles.container}>
                        <Typography variant="h4">{t('were_full')}</Typography>
                        <br />
                        <Typography variant="h6">
                            {t('thanks_but_spots_taken')}
                        </Typography>
                    </div>
                </BrandedPage>
            </div>
        </div>
    )
})

export default EventFullPage;
