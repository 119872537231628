
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, Tooltip, AppBar } from '@material-ui/core';
import { getAllSessionFilesPromise, getSessionFilePromise } from './services/filesService';
import { FaDownload } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles({
    modal: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw'
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
        width: '80%',
        minWidth: '300px',
        // padding: '15px 0 0 0',
        padding: '1rem',
        maxHeight: '98%'
    },
    table: {
        // margin: '1rem 0 1rem 0'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: '0 15px 0 15px',
        marginBottom: '6px'
    },
    headerText: {
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(5rem + 5px)',
        alignItems: 'center',
        fontWeight: 800
    },
    tableCell: {
        fontSize: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 2rem 0 2rem'
    },
    buttonRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0.5rem'
    },
    button: {
        margin: '0 0.25rem 0 0.25rem'
    },
    downloadButton: {
        padding: '5px',
        minWidth: '0px',
    }
})


export const SessionFiles = React.memo(({ sessionId, token, setSessionFileCount }) => {
    const styles = useStyles();
    const [allFiles, setAllFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation('client_options');

    const getAllSessionFiles = useCallback(async () => {
        return await getAllSessionFilesPromise(sessionId, token);
    }, [sessionId, token]);

    const checkAllFiles = useCallback(async () => {
        setIsLoading(true);
        const data = await getAllSessionFiles();
        setAllFiles(data.map(i => {
            let key = i.Key.split('/').slice(2).join('/');
            let filename = i.Key.split('_').slice(2).join('_');
            return { key: key, name: filename }
        }));
        setIsLoading(false);
    }, [getAllSessionFiles])

    useEffect(() => {
        checkAllFiles();
    }, [checkAllFiles]);

    useEffect(() => {
        setSessionFileCount(allFiles.length < 5 ? allFiles.length : 5); // use to set marginTop of FilePopover. If allFiles > 5, popover will be scrollable
    }, [setSessionFileCount, allFiles])

    const getSessionFile = useCallback(async (filename) => {
        const data = await getSessionFilePromise(sessionId, filename, token);
        return data;
    }, [sessionId, token]);

    const downloadFile = useCallback((url, file) => {
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': `application/octet-stream`,
            },
        }).then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.download = file.name;

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
    }, []);

    const tableRef = useRef();

    return (<>
        <AppBar position="static">
            <Typography variant="h5" className={styles.headerText}>{t('shared_files_tooltip')}</Typography>
        </AppBar>
        <TableContainer className={styles.table}>
            <Table size="small" ref={tableRef}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('filename_text')}</TableCell>
                        <TableCell>{t('actions_text')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading ? <TableRow >
                        <TableCell colSpan={2}>
                            <Typography style={{ justifyContent: 'center' }} className="loading-text">{t('getting_files_text')}<span className="loading-ellipsis" /></Typography>
                        </TableCell>
                    </TableRow> : allFiles.map(file => {
                        return <TableRow key={file.key}>
                            <TableCell >
                                {file.name}
                            </TableCell>
                            <TableCell><Tooltip title={t('download_text')}><Button className={styles.downloadButton}><FaDownload onClick={async () => {
                                const url = await getSessionFile(file.key);
                                await downloadFile(url, file);
                            }} /></Button></Tooltip></TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    </>
    )

})