import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import moment from 'moment'
import seasiLogo from './resources/seasi-logo.png'
import pfmLogo from './resources/pfm-logo.png'
import vvtvLogo from './resources/vv-logo.png'
import ewLogo from './resources/ew-logo.png'
import background from './resources/black-bg.jpg'
import ReactCodeInput from 'react-code-input';
import CountdownDisplay from './CountdownDisplay';
import TriviaQuestions from './TriviaQuestions';

const useStyles = makeStyles({
    root: {
        height: '100%',
        width: '100%',
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        // background: `url(${background})`,
        // backgroundSize: 'cover',
        // background: 'rgba(21,27,23)',
        background: 'black',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 1
    },
    background: {
        background: `url(${background})`,
        // background: 'rgba(21,27,23)',
        backgroundSize: 'cover',
        flex: 1
    },
    logoBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    teamLogo: {
        height: '100%',
        maxHeight: '150px'
    },
    headerLogo: {
        height: '100%',
        maxHeight: '80px'
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        width: '55%',
        minWidth: '400px',
        maxWidth: '800px',
        minHeight: '300px'
    },
    continue: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 2rem 2rem 2rem',
        zIndex: 1002,
    },
    continueBack: {
        zIndex: 1001,
        position: 'absolute',
        height: '100%',
        width: '100%',
        backdropFilter: 'blur(12px)'
    },
    appbar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem 2rem 1rem 2rem',
        boxSizing: 'border-box',
        zIndex: 1000
    },
    fixedHeader: {
        position: 'static',
        top: 0,
        width: '100%',
        height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    fixedFooter: {
        position: 'static',
        bottom: 0,
        width: '100%',
        // height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    white: {
        color: 'white'
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem'
    },
    margin: {
        margin: '1rem'
    },
    buttonRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    buttonBox: {
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        height: '100%',
        width: '95%',
        margin: 'auto',
        // minWidth: '300px',
        // maxWidth: '800px',
        color: 'white',
        position: 'relative'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        // marginTop: 'auto',
        minHeight: '68%',
        height: 'auto',
        marginBottom: 'auto'
        // width: '55%',
        // minWidth: '400px',
        // maxWidth: '800px',
        // minHeight: '300px'
    }
})

const logos = {
    'SEA': seasiLogo,
    'PFM': pfmLogo,
    'VTV': vvtvLogo,
    'EWX': ewLogo,
    'CPT': seasiLogo,
}

const BrandedPage = React.memo(({
    team = 'SEA',
    ...props
}) => {
    const styles = useStyles();

    return (
        <>
            <div className={styles.fixedHeader}>
                <img alt="logo" src={logos[team]} className={styles.headerLogo} />
            </div>
            <div className={styles.background}>
                <div className={styles.content}>
                    {props.children}
                </div>
            </div>
            <div className={styles.fixedFooter}>
                <Typography className={styles.white} variant="caption">&copy; {moment().format('YYYY')} Screen Engine/ASI</Typography>
            </div>
        </>
    )
    // }
})

export default BrandedPage;