import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import HelpModal from './HelpModal';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    error: {
        display: ({ hideInfo }) => hideInfo ? 'none' : 'flex',
        alignItems: 'center',
        minHeight: 'calc(3rem + 6px)',
        textAlign: 'center',
        padding: '0 1rem 0 1rem',
        boxSizing: 'border-box'
    }
})

const renderMessage = (message) => {
    if (message instanceof Function) {
        const Component = message
        return <Component />
    } else {
        return message || "\u00a0"
    }
}

const InfoMessage = React.memo(({
    failed,
    error,
    info,
    messages,
    defaultMessage,
    noRetry,
    hideInfo
}) => {
    const styles = useStyles({ hideInfo });
    const {t} = useTranslation('self_tech_check_step_1');

    return (
        <Typography variant="body1" className={styles.error}>
            {failed ? <>
                {noRetry ? t('failed_too_many')
                    : messages[error.message] ? <>
                        {messages[error.message].message instanceof Function ? messages[error.message].message(error) : messages[error.message].message}
                        {messages[error.message].slides?.length && <HelpModal slides={messages[error.message].slides} />}
                    </> : (defaultMessage || t('error_click_to_retry'))}
            </> : Array.isArray(info) ? <>
                {info.map((mx, i) => (<React.Fragment key={`infomessage${i}`}>
                    {renderMessage(mx)}
                    {(i < info.length - 1) && <><br /><br /></>}
                </React.Fragment>))}
            </> : renderMessage(info)}
        </Typography>
    )
})

export default InfoMessage;