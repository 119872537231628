import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flex: 1,
        // width: '100%',
        // height: '100%'
    },
    title: {
        flex: 1,
        display: 'flex',
        alignItems: 'flex-end',
        padding: '1rem'
    },
    body: {
        textAlign: 'center',
        flex: 2,
        padding: '2rem'
        // flexBasis: '40%'
    },
    spacing: {
        marginBottom: '1rem'
    },
    bold: {
        fontWeight: '500'
    }
})

const CompleteMessage = React.memo(({ loading, approved, canceled, checkInTime }) => {
    const styles = useStyles();
    const {t} = useTranslation('self_tech_check_step_6');
    const title = approved ? t('tech_check_complete') : canceled ? t('registration_cancelled') : t('thank_you')
    const checkInString = new Date(checkInTime).toLocaleString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hourCycle: 'h12', timeZoneName: 'short' })

    return (
        <div className={styles.root}>
            {loading ? <CircularProgress /> : <>
                <Typography variant="h4" className={styles.title}>{title}</Typography>
                <div className={styles.body}>
                    {approved ? <>
                        <Typography variant="body1" className={styles.spacing}>{t('approved_see_you_on')}<br /><span className={styles.bold}>{checkInString}</span>.</Typography>
                        <Typography variant="body1" className={styles.spacing}>{t('separate_conf_email')}</Typography>
                    </> : canceled ? <>
                        <Typography variant="body1" className={styles.spacing}>{t('sorry_youre_unable')}</Typography>
                    </> : <>
                        <Typography variant="body1" className={styles.spacing}>{t('event_is_full')}</Typography>
                    </>}
                    <Typography variant="body1" className={styles.spacing}>{t('reach_out_to_us')} <a href="mailto:onlineevents@previewfreemovies.com">onlineevents@previewfreemovies.com</a>.</Typography>
                    <Typography variant="body1" className={styles.spacing}>{t('window_can_close')}</Typography>
                </div>
            </>}
        </div>
    )
})

export default CompleteMessage;