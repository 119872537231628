import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Paper, CircularProgress, Stepper, Step, StepLabel, Typography, Button, Grow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import WebcamChecker from './WebcamChecker';
import MicChecker from './MicChecker';
import BrowserConfigChecker from './BrowserConfigChecker';
import { useRetry, useSystemCheck, withNextRetry } from './hooks';
import './SystemChecker.css'
import CheckInstructions from './CheckInstructions';
import SessionFlowChecker from './SessionFlowChecker';
import IntroChecker from './IntroChecker';
import PhotoUpload from './PhotoUpload';
import CompleteMessage from './CompleteMessage';
import EventConfirmChecker from './EventConfirmChecker';
import ZoomChecker from './ZoomChecker';
import {useTranslation} from 'react-i18next';


const useStyles = makeStyles({
    root: {
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        alignSelf: 'center',
        minHeight: '60%',
        width: '60%',
        minWidth: '660px',
        maxWidth: '780px',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        zIndex: 999
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // alignSelf: 'center',
        height: '100%',
        width: '100%',
        // minWidth: '660px',
        // maxWidth: '780px',
        position: 'absolute'
    },
    margin: {
        margin: '1rem'
    },
    buttonBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '6px'
    }
})

const WelcomeInstructions = () => {
    const {t} = useTranslation('self_tech_check_step_1');
    return <CheckInstructions
        title={t('lets_get_started')}
        instructions={[
            t('will_start_tech_checks'),
            t('click_next_to_continue')
        ]}
    />
};

const BrowserInstructions = () => {
    const {t} = useTranslation('self_tech_check_step_2')
    return <CheckInstructions
        title={t('browser_settings')}
        instructions={[
            t('check_browser_reqs'),
            t('click_next_to_continue')
        ]}
    />
}

const AvInstructions = () => {
    const {t} = useTranslation('self_tech_check_step_3')
    return <CheckInstructions
        title={t('title')}
        instructions={[
            t('confirm_machine_AV_capabilities'),
            t('click_next_to_continue'),
            t('not_being_recorded')
        ]}
    />
    }

const VideoInstructions = () => {
    const {t} = useTranslation('self_tech_check_step_4');
    return <CheckInstructions
        title={t('title')}
        instructions={[
            t('run_through_practice'),
            t('click_next_to_continue')
        ]}
    />
    }

const PhotoInstructions = () => {
    const {t} = useTranslation('self_tech_check_step_5');
    return <CheckInstructions
        title={t('title')}
        instructions={[
            t('capture_photo_for_security'),
            () => <i>{t('note_photo_of_face_only_1')}<b>{t('note_photo_of_face_only_bold')}</b>{t('note_photo_of_face_only_2')}</i>,
            t('click_next_to_continue')
        ]}
    />
}

const introConfirmation = (enableGeoRestriction, allowedCountries, enhancedGeoRestriction, subGeoRestrictionType, subGeoRestriction, geoData) => (props) => (
    <IntroChecker
        enableGeoRestriction={enableGeoRestriction}
        allowedCountries={allowedCountries}
        enhancedGeoRestriction={enhancedGeoRestriction}
        subGeoRestrictionType={subGeoRestrictionType}
        subGeoRestriction={subGeoRestriction}
        geoData={geoData}
        {...props}
    />
)

const browserConfirmation = (allowedBrowsers, allowMobile) => (props) => (
    <BrowserConfigChecker
        allowedBrowsers={allowedBrowsers}
        allowMobile={allowMobile}
        {...props}
    />
)

const eventTimeConfirmation = (checkInTime) => (props) => (
    <EventConfirmChecker
        checkInTime={checkInTime}
        {...props}
    />
)

const SystemChecker = React.memo(({ hidden, startSessionFlow, sessionFlowStatus, onStatusChange, onComplete, uploadPhoto, techCheckComplete, isApproved, isCanceled, enableGeoRestriction, allowedCountries, enhancedGeoRestriction, subGeoRestrictionType, subGeoRestriction, geoData, allowedBrowsers, allowMobile, focusGroup, checkInTime, toScheduling }) => {
    const {t} = useTranslation('headers');
    const checkSteps = useMemo(() => {
        console.log('CHECK STEPS CHANGED!!!!!!!!!!!!!!!!!!!!!!!!!!')
        
        return [
        {
            label: t("confirm_device"),
            components: [
                withNextRetry(WelcomeInstructions, { forDisplay: true }),
                withNextRetry(introConfirmation(enableGeoRestriction, allowedCountries, enhancedGeoRestriction, subGeoRestrictionType, subGeoRestriction, geoData), { retryLimit: 0, noFail: true })
            ],
            status: "Confirming Device"
        },
        {
            label: t("browser_settings"),
            components: [
                withNextRetry(BrowserInstructions, { forDisplay: true }),
                withNextRetry(browserConfirmation(allowedBrowsers, allowMobile), { retryLimit: 3 })
            ],
            status: "Checking Browser"
        },
        {
            label: t("cam_and_mic"),
            components: [
                withNextRetry(AvInstructions, { forDisplay: true }),
                withNextRetry(WebcamChecker, { retryLimit: 3 }),
                withNextRetry(MicChecker, { retryLimit: 3 })
            ],
            status: "Checking Cam & Mic"
        },
        ...[!focusGroup ? {
            label: t("video_playback"),
            components: [
                withNextRetry(VideoInstructions, { forDisplay: true, goToSessionFlow: { complete: true } }),
                withNextRetry(SessionFlowChecker, { retryLimit: 3, goToSessionFlow: { retry: true } })
            ],
            status: "Checking Session Flow"
        } : null],
        {
            label: t("photo_capture"),
            components: [
                withNextRetry(PhotoInstructions, { forDisplay: true }),
                PhotoUpload
            ],
            status: "Photo Capture"
        },
        {
            label: t("event_conf"),
            components: [
                withNextRetry(ZoomChecker, { retryLimit: 0, noFail: true, failNext: true }),
                withNextRetry(eventTimeConfirmation(checkInTime), { retryLimit: 0, cancelRegistration: true }),
            ],
            status: "Confirming Event"
        }
    ].filter(x => !!x)
}, [enableGeoRestriction, allowedCountries, enhancedGeoRestriction, subGeoRestrictionType, subGeoRestriction, geoData, allowedBrowsers, allowMobile, focusGroup, checkInTime])

    const styles = useStyles();
    const [currentStepIndex, setCurrentStepIndex] = useState((techCheckComplete) ? checkSteps.length : 0)
    const [substepIndex, setSubstepIndex] = useState(0)
    const [status, setStatus] = useState(isCanceled ? { failed: true, failedStep: checkSteps.length - 1 } : {})
    const [canceled, setCanceled] = useState(isCanceled || false)

    const onChangeRef = useRef(onStatusChange)

    const StepComponent = useMemo(() => (checkSteps[currentStepIndex]?.components[substepIndex]), [checkSteps, currentStepIndex, substepIndex])

    const handleStepError = useCallback((error, noRetry, cancel) => {
        console.log('STEP ERROR', error)
        setStatus({
            current: checkSteps[currentStepIndex].status,
            failed: true,
            failedStep: currentStepIndex,
            error,
            noRetry,
            cancel
        })
        setCanceled(cancel)
    }, [checkSteps, currentStepIndex])

    const handleStepRetry = useCallback((goToSessionFlow) => {
        console.log('STEP RETRY')
        const current = checkSteps[currentStepIndex].status
        setStatus({
            current,
            retry: true
        })
        if (goToSessionFlow?.retry) startSessionFlow()
    }, [checkSteps, currentStepIndex, startSessionFlow])

    const handleStepComplete = useCallback((goToSessionFlow) => {
        if (checkSteps[currentStepIndex].components.length === substepIndex + 1) {
            setSubstepIndex(0)
            if ((checkSteps.length === currentStepIndex + 1) && onComplete) onComplete()
            setCurrentStepIndex(prev => prev + 1)
        } else {
            setSubstepIndex(prev => prev + 1)
        }
        if (goToSessionFlow?.complete) startSessionFlow()
    }, [checkSteps, currentStepIndex, substepIndex, startSessionFlow, onComplete])

    useEffect(() => {
        console.log('STATUS CHANGE FUNCTION CHANGED!!!!!!!')
        onChangeRef.current = onStatusChange
    }, [onStatusChange])

    useEffect(() => {
        if (canceled && onComplete) onComplete(true)
    }, [canceled, onComplete])

    useEffect(() => {
        console.log('ON STATUS CHANGE', status)
        if (status.current && !status.cancel && onChangeRef.current) onChangeRef.current(status)
    }, [status])

    useEffect(() => {
        console.log('FROM USE EFFECT', currentStepIndex, checkSteps, substepIndex)
        if (currentStepIndex >= checkSteps.length) {
            setStatus({
                complete: true
            })
        } else {
            setStatus({
                current: checkSteps[currentStepIndex].status
            })
        }
    }, [checkSteps, currentStepIndex, substepIndex])

    const checkStepper = useMemo(() => {
        return checkSteps.map((step, i) => (<Step key={`tc-step${i}`}>
            <StepLabel error={status.failed && status.failedStep === i}>{step.label}</StepLabel>
        </Step>))
    }, [checkSteps, status])

    const handleScheduleTechCheck = useCallback(() => {
        if (toScheduling) toScheduling()
    }, [toScheduling])

    return (
        <Grow in={!hidden}>
            <Paper className={styles.root}>
                <Stepper activeStep={currentStepIndex}>
                    {checkStepper}
                </Stepper>
                {(!canceled && StepComponent) ? <>
                    <SwitchTransition mode='out-in'>
                        <CSSTransition
                            key={`tc-step${currentStepIndex}-${substepIndex}`}
                            addEndListener={(node, done) => {
                                node.addEventListener("transitionend", done, false);
                            }}
                            classNames="tc-step"
                        >
                            <StepComponent
                                onComplete={handleStepComplete}
                                onError={handleStepError}
                                onRetry={handleStepRetry}
                                noRetry={status.noRetry}
                                afterFail={handleScheduleTechCheck}
                                externalStatus={sessionFlowStatus}
                                uploadPhoto={uploadPhoto}
                            />
                        </CSSTransition>
                    </SwitchTransition>
                    {/* <StepComponent
                        onComplete={handleStepComplete}
                        onError={handleStepError}
                        onRetry={handleStepRetry}
                        noRetry={status.noRetry}
                        afterFail={handleScheduleTechCheck}
                        externalStatus={sessionFlowStatus}
                        uploadPhoto={uploadPhoto}
                    /> */}
                </> : <>
                    <CompleteMessage
                        loading={!techCheckComplete}
                        approved={isApproved}
                        canceled={isCanceled}
                        checkInTime={checkInTime}
                    />
                </>}
            </Paper>
        </Grow>
    )
})

export default SystemChecker;
